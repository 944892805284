.sidebar {
    .sidebar-share {
        @include breakpoint(tablet) {
            display: none;
        }

        width: 100%;
        margin-bottom: 20px;
        padding: 30px 25px;
        font-size: 18px;
        color: #fff;
        text-align: center;
        font-weight: 400;
        background-color: $color-primary;
        .social-icons {
            margin: 0 auto;
            margin-top: 16px;
            width: 159px;
            list-style-type: none;
            padding-left: 0;
            li {
                float: left;
                padding-right: 13px;
                &:last-child {
                    padding-right: 0;
                }
            }
            a {
                padding-top: 2px;
                text-align: center;
                display: block;
                width: 30px;
                height: 30px;
                border: 1px solid #fff;
                @include border-radius(2px);
                color: #fff;
            }
        }
    }
    .share-mobile {
        display: none;
        margin-bottom: 0;
        margin-top: 30px;
        .sidebar-share {
            display: block !important;
        }
        @include breakpoint(tablet) {
            display: block;
        }
    }
    .sidebar-newsletter {
        text-align: center;
        padding: 20px 24px;
        @include breakpoint(tablet) {
            display: none;
        }

        .heading {
            font-size: 18px;
            color: $color-primary;
        }
        .subheading {
            padding-bottom: 16px;
            margin-bottom: 16px;
            font-size: 12px;
            color: #6d6d6d;
            background: transparent url("/images/breaker.png") center bottom no-repeat;
        }
        .form-group {
            margin-bottom: 8px;
        }
        .form-control {
            text-align: center;
            height: 36px;
            border: 1px solid #d3d3d3;
        }
        .btn-primary {
            margin-bottom: 8px;
            font-size: 12px;
            width: 100%;
            padding: 9px 29px;
        }
        .link {
            font-size: 12px;
        }
    }
    .sidebar-catergory {
        @include breakpoint(tablet) {
            display: none;
        }

        .top-half {
            padding: 20px 25px;
            border-bottom: 1px solid #d3d3d3;
        }
        .bottom-half {
            padding: 20px 25px;
        }
        .heading {
            margin-bottom: 10px;
            text-align: center;
            color: $medium-grey;
            font-size: 27px;
        }
        .subheading {
            text-transform: uppercase;
            font-weight: 800;
            text-align: center;
            color: #d4d4d4;
            font-size: 12px;
        }
        .icon {
            margin: 0 auto;
        }
        .category-listing {
            color: #6d6d6d;
            font-size: 15px;
            list-style-type: none;
            padding-left: 0;
            line-height: 22px;
            li {
                padding-left: 14px;
                margin-bottom: 10px;
                background: transparent url("/images/icon-list-chevron-right.svg") left 48% no-repeat;
            }
            a {
                color: #6d6d6d;
                &:hover {
                    color: $color-primary;
                    text-decoration: none;
                }
            }
        }
    }
    .rss-feed {
        font-size: 15px;
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 20px;
        @include breakpoint(tablet) {
            display: none;
        }
    }
    .sidebar-item {
        border: 1px solid #d3d3d3;
        margin-bottom: 20px;
    }
    .sidebar-preview {
        @include breakpoint(tablet) {
            display: none;
        }

        .heading {
            padding: 20px 25px;
            font-weight: 800;
            color: $color-primary;
            font-size: 18px;
            text-align: center;
        }
        .article-title {
            font-size: 12px;
            color: $color-primary;
        }
        .excerpt {
            font-size: 12px;
            color: $medium-grey;
        }
        .time-stamp {
            padding-top: 10px;
            margin-top: 10px;
            background: transparent url("/images/breaker-sidebar.png") left top no-repeat;
            color: $medium-grey;
            font-size: 11px;
            .icon {
                margin-top: -2px;
                width: 10px;
            }
        }
        .img-container {
            height: 108px;
            overflow: hidden;
        }
        .copy {
            padding: 20px 25px;
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
    .sidebar-contact {
        @include breakpoint(tablet) {
            display: none;
        }

        .subheading {
            text-transform: uppercase;
            font-weight: 800;
            text-align: center;
            color: #d4d4d4;
            font-size: 12px;
            @include breakpoint(tablet) {
                font-size: 17px;
            }
        }
        .heading {
            margin-top: 20px;
            text-align: center;
            font-size: 27px;
            color: $medium-grey;
            @include breakpoint(tablet) {
                font-size: 38px;
            }
        }
        .icon {
            display: block;
            margin: 0 auto;
            @include breakpoint(tablet) {
                width: 36px;
            }
        }
        .location {
            font-family: $font-tert;
            padding: 35px 20px 15px 20px;
            margin-top: 20px;
            border-top: 1px solid #d3d3d3;
            color: $medium-grey;
            font-size: 13px;
            @include breakpoint(tablet-large) {
                a, strong {
                    display: block;
                    width: 250px;
                    margin: 20px auto 0 auto;
                }
            }

            @include breakpoint(tablet) {
                font-size: 17px;
            }
        }
        .table-sidebar {
            margin-left: 20px;
            margin-right: 20px;
	    font-size: 13px;
            @include breakpoint(tablet) {
                width: 250px;
                margin: 0px auto 40px auto;
            }

            td {
                padding: 1px;
                border: none;
                @include breakpoint(tablet) {
                    font-size: 17px;
                }
            }
        }
    }
    .sidebar-insurance-tile {
        padding: 25px 20px;
        .btn-primary {
            margin-top: 20px;
            font-size: 12px;
            width: 100%;
            padding: 9px 29px;
            @include breakpoint(tablet) {
                font-size: 16px;
            }
        }
        .caption {
            position: relative;
            text-align: center;
            @include breakpoint(tablet-large) {
                padding: 40px 15px 30px 15px;
            }

            @include breakpoint(tablet) {
                padding: 0px 15px 0 15px;
            }

            .sub-heading {
                display: block;
                color: #d4d4d4;
                font-weight: 600;
                font-size: 12px;
                text-transform: uppercase;
            }
            .heading {
                display: block;
                font-size: 27px;
                @include breakpoint(tablet-large) {
                    font-size: 24px;
                }

                @include breakpoint(tablet) {
                    margin-bottom: 5px;
                }
            }
            .copy {
                display: block;
                font-size: 15px;
                color: #6d6d6d;
                @include breakpoint(tablet) {
                    font-size: 17px;
                }
            }
            .icon-container {
                padding-top: 15px;
                height: 66px;
                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }
    }
}
