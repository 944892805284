.footer-wrapper {
    position: relative;
    z-index: 10;
}

.footer-header {
    min-height: 549px;
    width: 100%;
    background: transparent url("/images/footer-header-bg.png") center top no-repeat;
    background-size: cover;
    @include breakpoint(tablet-large) {
        min-height: 365px;
    }

    @include breakpoint(tablet) {
        background: none;
        min-height: 0px;
    }

    .mobile-banner {
        display: none;
        width: 100%;
        @include breakpoint(tablet) {
            display: block;
        }
    }
}

.footer-chat {
    padding: 15px 0;
    color: #fff;
    background-color: #2f2f2f;
    @include breakpoint(tablet-large) {
        text-align: center;
    }

    .copy {
        margin-top: 9px;
        font-size: 20px;
    }
    .contact-btns {
        text-align: right;
        @include breakpoint(tablet-large) {
            margin: 25px auto 10px auto;
            width: 317px;
        }

        @include breakpoint(tablet) {
            width: 314px;
        }
        @include breakpoint(phone) {
            width: 290px;
        }
        &.singular{
            .btn-secondary {
                width: 100%;
            }            
        }

        .btn-secondary {
            margin-left: 18px;
            @include breakpoint(tablet-large) {
                margin-left: 0px;
                &:last-child {
                    margin-left: 10px;
                }
            }
            @include breakpoint(phone) {
                padding: 9px 21px;
                margin-left: 0px;
                &:last-child {
                    margin-left: 0px;
                }
            }

            @include breakpoint(tablet) {
                padding: 9px 21px;
            }
        }
    }
}

footer.main {
    color: #fff;
    background-color: #2f2f2f;
}

.footer-nav-container {
    padding-top: 60px;
    padding-bottom: 20px;
    @include breakpoint(tablet-large) {
        padding-top: 41px;
    }

    .external-link {
        margin-right: 30px;
        &.tw {
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #55acee;
            }
        }
        &.fb {
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #3b5998;
            }
        }
        &.in {
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #0077b5;
            }
        }
    }
}

.footer-nav {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 30px;
    li {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 600;
        a {
            color: #fff;
        }
        ul {
            margin-top: 5px;
            padding-left: 15px;
            li {
                position: relative;
                list-style-type: none;
                a {
                    font-weight: 400;
                }
                &:before {
                    top: 5px;
                    left: -12px;
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 9px;
                    background: transparent url("/images/icon-footer-chevron.svg") left top no-repeat;
                }
            }
        }
    }
    &.alt {
        a {
            color: #b1b1b1;
        }
    }
}

.newsletter-signup-container {
    margin-top: 32px;
    padding: 30px;
    text-align: center;
    background-color: #202020;
    .heading {
        font-size: 18px;
        color: #fff;
    }
    .sub-heading {
        position: relative;
        padding-bottom: 18px;
        margin-bottom: 20px;
        font-size: 15px;
        color: #d5d5d5;
        &:after{
            content:"";
            width:59px;
            background-color: $color-secondary;
            height: 1px;
            margin: 0 auto;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
        }
    }
    .btn-tert {
        width: 100%;
    }
    .form-control {
        height: 40px;
        font-size: 15px;
        color: #b2b2b2;
        text-align: center;
        background-color: #333333;
        border-color: #333333;
    }
}

footer.copyright {
    padding: 20px 0;
    color: #fff;
    background-color: #252525;
    @include breakpoint(tablet-large) {
        text-align: center;
    }
}

.brainbox-link {
    text-align: right;
    @include breakpoint(tablet-large) {
        margin-top: 10px;
        text-align: center;
    }

    a {
        color: #fff;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}

.brainbox-logo {
    margin-top: -4px;
}

.footer-menu-mobile {
    display: none;
    @include breakpoint(tablet-large) {
        display: block;
    }

    @include breakpoint(tablet) {
        display: none;
    }
}

.footer-menu-desktop {
    @include breakpoint(tablet-large) {
        display: none;
    }
}
.dtable{
    display: none;
    margin: 0 auto;
    @include breakpoint(tablet) {
        display: table;
    }
}
.mobile-social {
    margin-top: 30px;
    margin-bottom: 30px;
    display: none;
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    li {
        padding: 0 25px;
        display:inline-block;
        *display:inline; /*IE7*/
        *zoom:1; /*IE7*/
        text-align: center;
        vertical-align: middle;
        a{
            display: block;
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
        }
        @include breakpoint(phone) {
            padding: 0 7px;
        }
    }
    .external-link {
        &.tw {
            margin-left: 15px;
            line-height: 0px;
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #55acee;
            }
        }
        &.fb {
            cursor: pointer;
            line-height: 0px;
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #3b5998;
            }
        }
        &.in {
            cursor: pointer;
            line-height: 0px;
            font-size: 34px;
            color: white;
            &:hover,
            &:active,
            &:focus {
                color: #0077b5;
            }
        }
    }
    @include breakpoint(tablet) {
        display: block;
    }
}

.status-dot {
    @include breakpoint(tablet) {
        display: none;
    }
}