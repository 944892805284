// ==========================================================================
// Base Styles
// ==========================================================================
// What your project's base elements should look like by default:

* {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing:antialiased;
	text-rendering: optimizeLegibility;
	font-family: $font-primary;
}

section {

}

h1 {
	font-size: 51px;
	color: $medium-grey;
	font-family: $font-primary;
}

h2 {
	font-family: $font-primary; 
	font-size: 30px;
	color: $color-primary;
}
h3 {
	font-family: $font-primary; 
	font-size: 25px;
	color: $color-secondary;
}

a {
	color: $color-primary;
	&:hover,
	&:focus,
	&:active{
		text-decoration: none;
		color: darken($color-primary, 30%);
	}
}

img {
	max-width: 100%;
}