// ==========================================================================
// Mixins
// ==========================================================================

// Misc Mixins
//--------------------------------------------------------------------------------//

// Truncating text: Requires element to be block or inline-block level
@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Form placeholder text style
@mixin placeholder-text($color: #c2c8cb, $text-transform: none) {
    color: $color;
    text-transform: $text-transform;
    &:-ms-input-placeholder {
        color: $color;
        text-transform: $text-transform;
    }
    &::-webkit-input-placeholder {
        color: $color;
        text-transform: $text-transform;
    }
}

@mixin border-radius($radius: 2px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin box-sizing($box) {
    //  content-box | border-box | inherit
    -webkit-box-sizing: $box;
    -moz-box-sizing: $box;
    box-sizing: $box;
}

@mixin box-shadow($hor: 0, $vert: 1px, $blur: 1px, $spread: 0, $color: #999999) {
    -moz-box-shadow: $hor $vert $blur $spread $color;
    -webkit-box-shadow: $hor $vert $blur $spread $color;
    box-shadow: $hor $vert $blur $spread $color;
}

@mixin box-shadow-light {
    // @todo: Fix this
    @include box-shadow(0, 1px, 1px, 0, whitesmoke);
}

@mixin tile {
    width: 100%;
    background-color: white;
}

@mixin linear-gradient($fromColor, $toColor) {
    background-color: $toColor;
    /* Fallback Color */
    background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
    /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient(top, $fromColor, $toColor);
    /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image: -moz-linear-gradient(top, $fromColor, $toColor);
    /* FF3.6 */
    background-image: -ms-linear-gradient(top, $fromColor, $toColor);
    /* IE10 */
    background-image: -o-linear-gradient(top, $fromColor, $toColor);
    /* Opera 11.10+ */
    background-image: linear-gradient(top, $fromColor, $toColor);
}


@mixin bg-gradient-l2r($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, right top, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(left, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(left, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(left, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(left, $start-colour, $end-colour);
    background-image:         linear-gradient(left, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}', gradientType='1');
}

// Color Modifiers @todo: NOT WORKING - fix
//--------------------------------------------------------------------------------//
@function xx-light($color) {
    @return lighten($color, 43%);
}

@function x-light($color) {
    @return lighten($color, 35%);
}

@function light($color) {
    @return lighten($color, 12%);
}

@function dark($color) {
    @return darken($color, 8%);
}

@function x-dark($color) {
    @return darken($color, 16%);
}

// Responsive Mixins
//--------------------------------------------------------------------------------//
@mixin breakpoint($point) {
    @if $point == desktop-large {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @if $point == desktop {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $point == tablet-large {
        @media (max-width: 991px) {
            @content;
        }
    }
    @else if $point == tablet {
        @media (max-width: 768px) {
            @content;
        }
    }
    @else if $point == phone {
        @media (max-width: 480px) {
            @content;
        }
    }
}

// Responive Mixin Useage Example
//    .page-wrap {
//      width: 75%;
//      @include breakpoint(tablet) { 
//        width: 60%; 
//      }
//    }