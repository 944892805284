// Buttons
//--------------------------------------------------------------------------------//

// Base
.btn {
    transition: all 0.2s;
}

// Primary
.btn-primary {
    padding: 11px 29px;
    background-color: $color-primary;
    border-color: $color-primary;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    @include border-radius(2px);
    &:hover,
    &:active,
    &:focus {
        background-color: darken($color-primary, 10%);
        border-color: darken($color-primary, 10%);
    }
}

// Secondary
.btn-secondary {
    padding: 9px 25px;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #838383;
    &:hover,
    &:active,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
    &.alt {
        border-color: #fff;
    }
}

// Secondary
.btn-tert {
    background-color: transparent;
    padding: 9px 25px;
    font-size: 15px;
    font-weight: 800;
    text-transform: uppercase;
    color: $color-secondary;
    border: 1px solid $color-secondary;
    &:hover,
    &:active,
    &:focus {
        color: #2f2f2f;
        background-color: $color-secondary;
        text-decoration: none;
    }
}

.btn-quad {
    padding: 11px 29px;
    background-color: $color-secondary;
    border-color: $color-secondary;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    @include border-radius(2px);
    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background-color: darken($color-secondary, 10%);
        border-color: darken($color-secondary, 10%);
    }
}

.read-more-icon {
    transition: width 0.1s;
    overflow: hidden;
    position: absolute;
    right: 40px;
    top: -21px;
    font-size: 36px;
    padding: 0px 10px 0 10px;
    line-height: 44px;
    display: block;
    width: 42px;
    height: 42px;
    color: #fff !important;
    background-color: $color-primary;
    @include border-radius(25px);
    .opened-chevron {
        display: none;
        font-size: 16px;
        float: right;
        margin-top: 6px;
        margin-right: 7px;
    }
    .opened {
        font-size: 15px;
        display: none;
        text-transform: uppercase;
        text-align: left !important;
        float: left;
        margin-top: 5px;
        margin-left: 11px;
        font-weight: 800;
    }
    &:hover,
    &:active,
    &:focus {
        transition: width 0.1s;
        text-decoration: none;
        width: 196px;
        // width: 75%;
        line-height: 32px;
        @include border-radius(25px);
        .closed {
            display: none;
        }
        .opened {
            display: inline;
        }
        .opened-chevron {
            display: block;
        }
    }
}

.homepage .js-cta-nav .btn-get-a-quote-nav {
    // opacity: 0;
}

.btn-get-a-quote-nav{
    position: relative;
    top: -1px;
    margin-bottom: -2px;
    height: 33px;
    padding: 6px 16px 5px 16px;
    color: #fff;
    background-color: $color-secondary;
}

.js-cta-nav {
    .btn-get-a-quote-nav {
        margin-right: -2060px;
        background-color: $color-secondary;
        transition: all 0.2s;
        // border-color: $color-secondary;
        // padding: 9px 12px 8px 12px;
        // font-size: 13px;
        &:hover,
        &:focus,
        &:active {
            background-color: darken($color-secondary, 10%);
            border-color: darken($color-secondary, 10%);
            color:#fff;
        }
    }
    &.open {
        .btn-get-a-quote-nav{
            margin-right: 0;
        }
    }
}

.js-cta-nav {
    margin-right: -30px;
    transition: all 0.2s;
    &.open {
        .nav li:last-child a {
            transition: all 0.2s;
            margin-right: 0 !important;
            // border-right-color: $white;
        }
        .btn-get-a-quote-nav {
            transition: all 0.2s;
            opacity: 1;
            margin-right: 0;
        }
    }
    &.js-stuck {
        .nav li:last-child a {
            transition: all 0s;
            // border-right-color: $white;
        }
        .btn-get-a-quote-nav {
            transition: all 0s;
            margin-right: 0;
        }
    }
}

.btn-contact-bar {
    float: left;
    padding: 12px 12px;
    width: 50%;
    font-size: 17px;
    background-color: $color-primary;
    color: $white;
    @include border-radius(0px);
    &:hover,
    &:focus,
    &:active {
        background-color: darken($color-primary, 10%);
        color: $white;
    }
    &:first-child {
        border-right: 1px solid #e8e8e8;
    }
    &:last-child {
        border-left: 1px solid #e8e8e8;
    }
}

.btn-quote-mobile-container {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-quote-mobile {
    padding: 11px 0;
    width: 100%;
    background-color: $color-secondary;
    border-color: $color-secondary;
    &:hover,
    &:focus,
    &:active {
        background-color: darken($color-secondary, 10%);
        border-color: darken($color-secondary, 10%);
    }
}

.btn-full {
    width: 100%;
    border-radius: 4px;
}