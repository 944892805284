@font-face {
  font-family: Gotham Bold;
  src: url("/fonts/gotham-bold.eot");
  src: url("/fonts/gotham-bold.eot?#iefix") format("embedded-opentype"), url("/fonts/gotham-bold.woff") format("woff"), url("/fonts/gotham-bold.ttf") format("truetype"), url("/fonts/gotham-bold.svg#b3bd5af04c0e7cf2188fad8c1f2958f5") format("svg");
  font-style: normal;
  font-weight: 200; }

@font-face {
  font-family: Gotham Book;
  src: url("/fonts/gotham-book.eot");
  src: url("/fonts/gotham-book.eot?#iefix") format("embedded-opentype"), url("/fonts/gotham-book.woff") format("woff"), url("/fonts/gotham-book.ttf") format("truetype"), url("/fonts/gotham-book.svg#b3bd5af04c0e7cf2188fad8c1f2958f5") format("svg");
  font-style: normal;
  font-weight: 200; }

.db {
  display: block; }

.opac0 {
  opacity: 0; }

.dib {
  display: inline-block; }

.rl {
  position: relative; }

.p100 {
  width: 100%; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px; }

.mb20 {
  margin-bottom: 20px; }

.mb100 {
  margin-bottom: 100px; }

.mb150 {
  margin-bottom: 150px; }

.pt5 {
  padding-top: 5px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pt20 {
  padding-top: 20px; }

.pb5 {
  padding-bottom: 5px; }

.pb10 {
  padding-bottom: 10px; }

.pb15 {
  padding-bottom: 15px; }

.pb20 {
  padding-bottom: 20px; }

.pl17 {
  padding-left: 17px; }

@media (max-width: 768px) {
  .tablet-pb0 {
    padding-bottom: 0 !important; } }

.phone-only {
  display: none !important; }
  @media (max-width: 480px) {
    .phone-only {
      display: block !important; } }

@media (max-width: 480px) {
  .phone-hidden {
    display: none !important; } }

* {
  box-sizing: border-box; }

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: "Varela Round", sans-serif; }

h1 {
  font-size: 51px;
  color: #434343;
  font-family: "Varela Round", sans-serif; }

h2 {
  font-family: "Varela Round", sans-serif;
  font-size: 30px;
  color: #25ABF0; }

h3 {
  font-family: "Varela Round", sans-serif;
  font-size: 25px;
  color: #4EE625; }

a {
  color: #25ABF0; }
  a:hover, a:focus, a:active {
    text-decoration: none;
    color: #084f74; }

img {
  max-width: 100%; }

.btn {
  transition: all 0.2s; }

.btn-primary {
  padding: 11px 29px;
  background-color: #25ABF0;
  border-color: #25ABF0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px; }
  .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: #0f91d3;
    border-color: #0f91d3; }

.btn-secondary {
  padding: 9px 25px;
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #838383; }
  .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
    color: #fff;
    text-decoration: none; }
  .btn-secondary.alt {
    border-color: #fff; }

.btn-tert {
  background-color: transparent;
  padding: 9px 25px;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  color: #4EE625;
  border: 1px solid #4EE625; }
  .btn-tert:hover, .btn-tert:active, .btn-tert:focus {
    color: #2f2f2f;
    background-color: #4EE625;
    text-decoration: none; }

.btn-quad {
  padding: 11px 29px;
  background-color: #4EE625;
  border-color: #4EE625;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px; }
  .btn-quad:hover, .btn-quad:active, .btn-quad:focus {
    color: #fff;
    background-color: #3bc216;
    border-color: #3bc216; }

.read-more-icon {
  transition: width 0.1s;
  overflow: hidden;
  position: absolute;
  right: 40px;
  top: -21px;
  font-size: 36px;
  padding: 0px 10px 0 10px;
  line-height: 44px;
  display: block;
  width: 42px;
  height: 42px;
  color: #fff !important;
  background-color: #25ABF0;
  border-radius: 25px; }
  .read-more-icon .opened-chevron {
    display: none;
    font-size: 16px;
    float: right;
    margin-top: 6px;
    margin-right: 7px; }
  .read-more-icon .opened {
    font-size: 15px;
    display: none;
    text-transform: uppercase;
    text-align: left !important;
    float: left;
    margin-top: 5px;
    margin-left: 11px;
    font-weight: 800; }
  .read-more-icon:hover, .read-more-icon:active, .read-more-icon:focus {
    transition: width 0.1s;
    text-decoration: none;
    width: 196px;
    line-height: 32px;
    border-radius: 25px; }
    .read-more-icon:hover .closed, .read-more-icon:active .closed, .read-more-icon:focus .closed {
      display: none; }
    .read-more-icon:hover .opened, .read-more-icon:active .opened, .read-more-icon:focus .opened {
      display: inline; }
    .read-more-icon:hover .opened-chevron, .read-more-icon:active .opened-chevron, .read-more-icon:focus .opened-chevron {
      display: block; }

.btn-get-a-quote-nav {
  position: relative;
  top: -1px;
  margin-bottom: -2px;
  height: 33px;
  padding: 6px 16px 5px 16px;
  color: #fff;
  background-color: #4EE625; }

.js-cta-nav .btn-get-a-quote-nav {
  margin-right: -2060px;
  background-color: #4EE625;
  transition: all 0.2s; }
  .js-cta-nav .btn-get-a-quote-nav:hover, .js-cta-nav .btn-get-a-quote-nav:focus, .js-cta-nav .btn-get-a-quote-nav:active {
    background-color: #3bc216;
    border-color: #3bc216;
    color: #fff; }

.js-cta-nav.open .btn-get-a-quote-nav {
  margin-right: 0; }

.js-cta-nav {
  margin-right: -30px;
  transition: all 0.2s; }
  .js-cta-nav.open .nav li:last-child a {
    transition: all 0.2s;
    margin-right: 0 !important; }
  .js-cta-nav.open .btn-get-a-quote-nav {
    transition: all 0.2s;
    opacity: 1;
    margin-right: 0; }
  .js-cta-nav.js-stuck .nav li:last-child a {
    transition: all 0s; }
  .js-cta-nav.js-stuck .btn-get-a-quote-nav {
    transition: all 0s;
    margin-right: 0; }

.btn-contact-bar {
  float: left;
  padding: 12px 12px;
  width: 50%;
  font-size: 17px;
  background-color: #25ABF0;
  color: white;
  border-radius: 0px; }
  .btn-contact-bar:hover, .btn-contact-bar:focus, .btn-contact-bar:active {
    background-color: #0f91d3;
    color: white; }
  .btn-contact-bar:first-child {
    border-right: 1px solid #e8e8e8; }
  .btn-contact-bar:last-child {
    border-left: 1px solid #e8e8e8; }

.btn-quote-mobile-container {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px; }

.btn-quote-mobile {
  padding: 11px 0;
  width: 100%;
  background-color: #4EE625;
  border-color: #4EE625; }
  .btn-quote-mobile:hover, .btn-quote-mobile:focus, .btn-quote-mobile:active {
    background-color: #3bc216;
    border-color: #3bc216; }

.btn-full {
  width: 100%;
  border-radius: 4px; }

.navbar-comm {
  margin-bottom: 0;
  min-height: 33px;
  background-color: #e9e9e9;
  border-radius: 0; }
  @media (max-width: 768px) {
    .navbar-comm {
      min-height: 20px;
      border: none; } }
  @media (max-width: 768px) {
    .navbar-comm .nav {
      text-align: center;
      margin: 2.5px -15px; } }
  .navbar-comm .nav li a {
    position: relative;
    top: -1px;
    margin-bottom: -2px;
    height: 33px;
    padding: 7px 16px 4px 16px;
    border-left: 1px solid #d7d7d7;
    color: #888888; }
    @media (max-width: 768px) {
      .navbar-comm .nav li a {
        text-align: right;
        display: inline;
        border: none !important; } }
  @media (max-width: 768px) {
    .navbar-comm .nav li {
      display: inline; }
      .navbar-comm .nav li:first-child a {
        float: left;
        text-align: left; } }
  .navbar-comm .nav li:last-child a {
    border-right: 1px solid #d7d7d7; }
    @media (max-width: 768px) {
      .navbar-comm .nav li:last-child a {
        float: right; } }
  @media (max-width: 768px) {
    .navbar-comm .dropdown-menu li {
      width: 100%; }
      .navbar-comm .dropdown-menu li a {
        text-align: right !important;
        width: 100%; }
      .navbar-comm .dropdown-menu li:first-child a {
        border-top: 1px solid #d7d7d7 !important; } }
  .navbar-comm .nav .open > a, .navbar-comm .nav .open > a:focus, .navbar-comm .nav .open > a:hover {
    border-color: #d7d7d7;
    color: #6f6f6f;
    background-color: #fff; }
    @media (max-width: 768px) {
      .navbar-comm .nav .open > a, .navbar-comm .nav .open > a:focus, .navbar-comm .nav .open > a:hover {
        background-color: transparent;
        border-bottom: 1px solid #d7d7d7; } }

.navbar-main {
  padding: 14px 0px 15px 0px;
  margin-bottom: 0;
  border-radius: 0; }
  .navbar-main .nav {
    padding-top: 2px; }
    .navbar-main .nav li a {
      padding: 8px 13px 8px 13px;
      border-left: 1px solid #d7d7d7;
      color: #888888;
      text-transform: uppercase; }
      .navbar-main .nav li a:hover {
        background-color: #fff;
        color: #555555; }
    .navbar-main .nav li:first-child a {
      border-left: none; }
    .navbar-main .nav li:last-child a {
      border-right: 1px solid #d7d7d7; }
  .navbar-main .dropdown-menu {
    min-width: 100%; }
    .navbar-main .dropdown-menu li a {
      border-left: none; }
    .navbar-main .dropdown-menu li a:hover {
      background-color: #e9e9e9; }
  .navbar-main .nav .open > a {
    border-color: #d7d7d7; }
  .navbar-main .fa-chevron-down {
    position: relative;
    margin-left: 11px;
    top: -2px;
    font-size: 10px; }
  .navbar-main .navbar-brand {
    padding: 4px 0;
    position: absolute;
    top: 0px;
    bottom: 0px;
    height: 100%;
    min-width: 150px;
    padding-top: 10px;
    padding-bottom: 10px; }
    .navbar-main .navbar-brand img {
      height: 100%; }
  .navbar-main .nav .open > a, .navbar-main .nav .open > a:focus, .navbar-main .nav .open {
    background-color: #fff;
    color: #555555; }

.nav-border {
  width: 100%;
  height: 3px;
  background: #25ABF0;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #25ABF0 0%, #89bddc 50%, #4EE625 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-primary', endColorstr='$color-secondary',GradientType=1 );
  /* IE6-9 */ }

.mobile-nav-border {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#96d402+0,729fe2+50,612592+100 */
  background: #4EE625;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #4EE625 0%, #89bddc 50%, #25ABF0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-secondary', endColorstr='$color-primary',GradientType=1 );
  /* IE6-9 */ }

.main-banner {
  width: 100%;
  height: 575px; }
  @media (max-width: 991px) {
    .main-banner {
      height: 573px; } }
  @media (max-width: 768px) {
    .main-banner {
      height: auto;
      background-size: cover;
      position: relative; } }
  .main-banner .carousel-indicators {
    bottom: 40px; }
    @media (max-width: 991px) {
      .main-banner .carousel-indicators {
        bottom: 0px; } }
    @media (max-width: 768px) {
      .main-banner .carousel-indicators {
        bottom: auto;
        top: 350px; } }
    @media (max-width: 480px) {
      .main-banner .carousel-indicators {
        top: 215px; } }
    .main-banner .carousel-indicators li {
      border-color: #25ABF0;
      margin: 0 1px;
      height: 12px;
      width: 12px; }
    .main-banner .carousel-indicators .active {
      height: 12px;
      width: 12px;
      border-color: #25ABF0;
      background-color: #25ABF0; }
  @media (max-width: 768px) {
    .main-banner .carousel-inner {
      overflow: visible; } }
  .main-banner .heading {
    font-size: 40px;
    color: #434343;
    font-weight: 800; }
    @media (max-width: 768px) {
      .main-banner .heading {
        text-align: center; } }
    @media (max-width: 480px) {
      .main-banner .heading {
        margin-bottom: 20px;
        font-size: 20px; } }
  .main-banner .sub-heading {
    margin-bottom: 25px;
    color: #434343;
    font-size: 16px; }
    @media (max-width: 768px) {
      .main-banner .sub-heading {
        text-align: center; } }
    @media (max-width: 480px) {
      .main-banner .sub-heading {
        display: none; } }
  .main-banner .light-indicator {
    border: 1px solid #434343 !important; }
  .main-banner .item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 575px; }
    @media (max-width: 768px) {
      .main-banner .item {
        background-position: top center;
        background-size: auto 376px;
        height: auto; } }
    @media (max-width: 480px) {
      .main-banner .item {
        background-size: auto 240px; } }
    .main-banner .item.dark .heading {
      color: #ffffff; }
    .main-banner .item.dark .sub-heading {
      color: #ffffff; }
    @media (max-width: 768px) {
      .main-banner .item.dark .heading {
        color: #434343; }
      .main-banner .item.dark .sub-heading {
        color: #434343; } }

.carousel-image {
  opacity: 0; }

.banner-copy {
  margin-top: 180px; }
  @media (max-width: 768px) {
    .banner-copy {
      margin-top: 375px;
      padding: 5px 0 30px 0; }
      .banner-copy .btn-primary {
        display: block;
        width: 240px;
        margin: 0 auto; } }
  @media (max-width: 480px) {
    .banner-copy {
      margin-top: 240px; } }

.status-dot {
  font-size: 12px; }
  .status-dot.online {
    color: #68dd61; }
  .status-dot.offline {
    color: #eb6358; }
  .status-dot.alt {
    position: relative;
    right: -7px;
    top: -1px; }

.nav-container {
  z-index: 30;
  left: 0;
  right: 0;
  background-color: #fff;
  position: fixed; }
  @media (max-width: 991px) {
    .nav-container {
      display: none; } }

section.page {
  padding-top: 102px; }
  @media (max-width: 991px) {
    section.page {
      padding-top: 0; } }

.container-animation-fix {
  padding-right: 0; }

.nav-mobile {
  display: none;
  background: #fff; }
  @media (max-width: 991px) {
    .nav-mobile .container {
      margin-bottom: 1px; } }
  @media (max-width: 991px) {
    .nav-mobile {
      display: block; } }
  .nav-mobile .navbar-brand {
    margin-left: -15px;
    height: 61px;
    padding: 10px 15px; }
    .nav-mobile .navbar-brand img {
      height: 100%; }
  .nav-mobile .mobile-menu {
    float: right;
    font-size: 35px;
    color: #25ABF0;
    cursor: pointer; }

#sb-site {
  box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.3); }
  @media (max-width: 991px) {
    #sb-site {
      padding-top: 98px !important; } }
  @media (max-width: 768px) {
    #sb-site {
      padding-top: 99px !important; } }

.sb-slidebar {
  background-color: #ffffff !important; }

.mobile-main-navigation-logo {
  padding: 12px 15px;
  min-height: 51px; }
  .mobile-main-navigation-logo img {
    width: 166px; }

.mobile-main-navigation {
  list-style-type: none;
  padding-left: 0; }
  .mobile-main-navigation .fa-chevron-down {
    float: right;
    font-size: 15px;
    margin-top: 8px; }
  .mobile-main-navigation li a {
    max-height: 59px;
    font-size: 20px;
    padding: 15px 20px;
    display: block;
    color: #888888;
    border-bottom: 1px solid #e0e0e0;
    font-size: 20px;
    color: #25ABF0; }
    .mobile-main-navigation li a:hover, .mobile-main-navigation li a:active, .mobile-main-navigation li a:focus {
      text-decoration: none; }
  .mobile-main-navigation .dropdown-backdrop {
    position: static; }
  .mobile-main-navigation .dropdown-menu {
    margin-top: 0;
    float: none;
    z-index: 8000;
    position: initial;
    width: 100%;
    padding: 0;
    border: none;
    box-shadow: none;
    background-color: #f5f5f5; }
    .mobile-main-navigation .dropdown-menu li a {
      font-size: 18px;
      padding: 15px 20px;
      padding-left: 30px;
      border-color: #d7d7d7;
      color: #434343; }
    .mobile-main-navigation .dropdown-menu li:last-child a {
      border-color: #f0dcf9; }
  .mobile-main-navigation .open .fa-chevron-down {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=6); }

.mobile-navigation-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 98px;
  color: white;
  background: #fff;
  line-height: 6rem;
  transform: translateY(0);
  transform: translate3d(0, 0, 0);
  transition: .25s transform;
  backface-visibility: hidden;
  z-index: 3000; }
  @media (max-width: 991px) {
    .mobile-navigation-container {
      display: block; } }
  .mobile-navigation-container.heads-up {
    transform: translateY(-99px);
    transform: translate3d(0, -99px, 0); }
  @media (max-width: 768px) {
    .mobile-navigation-container .navbar-comm .nav {
      height: 30px; }
    .mobile-navigation-container .navbar-comm li {
      top: -14px; }
      .mobile-navigation-container .navbar-comm li a {
        border-right: 1px solid #d7d7d7 !important;
        padding: 9px 16px 7px 16px; }
    .mobile-navigation-container .navbar-comm li:first-child a {
      float: none; }
    .mobile-navigation-container .navbar-comm li:last-child a {
      float: none;
      border-right: none; } }

.footer-wrapper {
  position: relative;
  z-index: 10; }

.footer-header {
  min-height: 549px;
  width: 100%;
  background: transparent url("/images/footer-header-bg.png") center top no-repeat;
  background-size: cover; }
  @media (max-width: 991px) {
    .footer-header {
      min-height: 365px; } }
  @media (max-width: 768px) {
    .footer-header {
      background: none;
      min-height: 0px; } }
  .footer-header .mobile-banner {
    display: none;
    width: 100%; }
    @media (max-width: 768px) {
      .footer-header .mobile-banner {
        display: block; } }

.footer-chat {
  padding: 15px 0;
  color: #fff;
  background-color: #2f2f2f; }
  @media (max-width: 991px) {
    .footer-chat {
      text-align: center; } }
  .footer-chat .copy {
    margin-top: 9px;
    font-size: 20px; }
  .footer-chat .contact-btns {
    text-align: right; }
    @media (max-width: 991px) {
      .footer-chat .contact-btns {
        margin: 25px auto 10px auto;
        width: 317px; } }
    @media (max-width: 768px) {
      .footer-chat .contact-btns {
        width: 314px; } }
    @media (max-width: 480px) {
      .footer-chat .contact-btns {
        width: 290px; } }
    .footer-chat .contact-btns.singular .btn-secondary {
      width: 100%; }
    .footer-chat .contact-btns .btn-secondary {
      margin-left: 18px; }
      @media (max-width: 991px) {
        .footer-chat .contact-btns .btn-secondary {
          margin-left: 0px; }
          .footer-chat .contact-btns .btn-secondary:last-child {
            margin-left: 10px; } }
      @media (max-width: 480px) {
        .footer-chat .contact-btns .btn-secondary {
          padding: 9px 21px;
          margin-left: 0px; }
          .footer-chat .contact-btns .btn-secondary:last-child {
            margin-left: 0px; } }
      @media (max-width: 768px) {
        .footer-chat .contact-btns .btn-secondary {
          padding: 9px 21px; } }

footer.main {
  color: #fff;
  background-color: #2f2f2f; }

.footer-nav-container {
  padding-top: 60px;
  padding-bottom: 20px; }
  @media (max-width: 991px) {
    .footer-nav-container {
      padding-top: 41px; } }
  .footer-nav-container .external-link {
    margin-right: 30px; }
    .footer-nav-container .external-link.tw {
      font-size: 34px;
      color: white; }
      .footer-nav-container .external-link.tw:hover, .footer-nav-container .external-link.tw:active, .footer-nav-container .external-link.tw:focus {
        color: #55acee; }
    .footer-nav-container .external-link.fb {
      font-size: 34px;
      color: white; }
      .footer-nav-container .external-link.fb:hover, .footer-nav-container .external-link.fb:active, .footer-nav-container .external-link.fb:focus {
        color: #3b5998; }
    .footer-nav-container .external-link.in {
      font-size: 34px;
      color: white; }
      .footer-nav-container .external-link.in:hover, .footer-nav-container .external-link.in:active, .footer-nav-container .external-link.in:focus {
        color: #0077b5; }

.footer-nav {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 30px; }
  .footer-nav li {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600; }
    .footer-nav li a {
      color: #fff; }
    .footer-nav li ul {
      margin-top: 5px;
      padding-left: 15px; }
      .footer-nav li ul li {
        position: relative;
        list-style-type: none; }
        .footer-nav li ul li a {
          font-weight: 400; }
        .footer-nav li ul li:before {
          top: 5px;
          left: -12px;
          position: absolute;
          content: "";
          width: 5px;
          height: 9px;
          background: transparent url("/images/icon-footer-chevron.svg") left top no-repeat; }
  .footer-nav.alt a {
    color: #b1b1b1; }

.newsletter-signup-container {
  margin-top: 32px;
  padding: 30px;
  text-align: center;
  background-color: #202020; }
  .newsletter-signup-container .heading {
    font-size: 18px;
    color: #fff; }
  .newsletter-signup-container .sub-heading {
    position: relative;
    padding-bottom: 18px;
    margin-bottom: 20px;
    font-size: 15px;
    color: #d5d5d5; }
    .newsletter-signup-container .sub-heading:after {
      content: "";
      width: 59px;
      background-color: #4EE625;
      height: 1px;
      margin: 0 auto;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0; }
  .newsletter-signup-container .btn-tert {
    width: 100%; }
  .newsletter-signup-container .form-control {
    height: 40px;
    font-size: 15px;
    color: #b2b2b2;
    text-align: center;
    background-color: #333333;
    border-color: #333333; }

footer.copyright {
  padding: 20px 0;
  color: #fff;
  background-color: #252525; }
  @media (max-width: 991px) {
    footer.copyright {
      text-align: center; } }

.brainbox-link {
  text-align: right; }
  @media (max-width: 991px) {
    .brainbox-link {
      margin-top: 10px;
      text-align: center; } }
  .brainbox-link a {
    color: #fff; }
    .brainbox-link a:hover, .brainbox-link a:focus, .brainbox-link a:active {
      text-decoration: none; }

.brainbox-logo {
  margin-top: -4px; }

.footer-menu-mobile {
  display: none; }
  @media (max-width: 991px) {
    .footer-menu-mobile {
      display: block; } }
  @media (max-width: 768px) {
    .footer-menu-mobile {
      display: none; } }

@media (max-width: 991px) {
  .footer-menu-desktop {
    display: none; } }

.dtable {
  display: none;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .dtable {
      display: table; } }

.mobile-social {
  margin-top: 30px;
  margin-bottom: 30px;
  display: none;
  list-style-type: none;
  padding-left: 0;
  width: 100%; }
  .mobile-social li {
    padding: 0 25px;
    display: inline-block;
    *display: inline;
    /*IE7*/
    *zoom: 1;
    /*IE7*/
    text-align: center;
    vertical-align: middle; }
    .mobile-social li a {
      display: block; }
    .mobile-social li:first-child {
      padding-left: 0; }
    .mobile-social li:last-child {
      padding-right: 0; }
    @media (max-width: 480px) {
      .mobile-social li {
        padding: 0 7px; } }
  .mobile-social .external-link.tw {
    margin-left: 15px;
    line-height: 0px;
    font-size: 34px;
    color: white; }
    .mobile-social .external-link.tw:hover, .mobile-social .external-link.tw:active, .mobile-social .external-link.tw:focus {
      color: #55acee; }
  .mobile-social .external-link.fb {
    cursor: pointer;
    line-height: 0px;
    font-size: 34px;
    color: white; }
    .mobile-social .external-link.fb:hover, .mobile-social .external-link.fb:active, .mobile-social .external-link.fb:focus {
      color: #3b5998; }
  .mobile-social .external-link.in {
    cursor: pointer;
    line-height: 0px;
    font-size: 34px;
    color: white; }
    .mobile-social .external-link.in:hover, .mobile-social .external-link.in:active, .mobile-social .external-link.in:focus {
      color: #0077b5; }
  @media (max-width: 768px) {
    .mobile-social {
      display: block; } }

@media (max-width: 768px) {
  .status-dot {
    display: none; } }

@media (max-width: 768px) {
  .tablet-hidden {
    display: none; } }

@media (max-width: 768px) {
  .tablet-no-pad {
    padding-left: 0px;
    padding-right: 0px; } }

section.partial {
  padding: 50px 0; }

hr.page-break {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #dcdcdc;
  margin: 60px 0;
  padding: 0; }
  @media (max-width: 768px) {
    hr.page-break {
      display: none; } }

.quick-quote {
  padding: 12px;
  margin-top: -43px;
  min-height: 70px;
  border: 6px solid white;
  background-color: #bdf6ae;
  border-radius: 8px; }
  @media (max-width: 991px) {
    .quick-quote {
      display: none;
      margin-top: 0; } }
  .quick-quote .btn-primary img {
    margin-top: -2px;
    margin-right: 14px; }
  .quick-quote .quote-copy {
    margin-top: 8px;
    display: inline-block;
    padding-left: 20px;
    color: #434343;
    font-size: 20px; }

.mobile-cta {
  padding: 20px 0 20px 0;
  background-color: #bdf6ae;
  min-height: 130px;
  text-align: center;
  color: #434343;
  font-size: 20px;
  display: none; }
  @media (max-width: 991px) {
    .mobile-cta {
      display: block; } }
  @media (max-width: 768px) {
    .mobile-cta {
      min-height: 130px; } }
  .mobile-cta .btn-primary {
    width: 240px;
    margin: 0 auto;
    margin-top: 10px;
    background-color: #4EE625;
    border-color: #4EE625; }
    .mobile-cta .btn-primary:hover, .mobile-cta .btn-primary:focus, .mobile-cta .btn-primary:active {
      background-color: #3bc216;
      border-color: #3bc216; }

.mobile-cta-yd {
  background-color: #4EE625;
  padding: 30px 0; }
  .mobile-cta-yd .btn-primary img {
    margin-top: -2px;
    margin-right: 14px; }

.young-drivers-signup {
  margin-top: 0px;
  background-color: #4EE625;
  border: none; }
  .young-drivers-signup .quote-copy {
    color: #fff; }
  .young-drivers-signup .btn-primary {
    background-color: transparent;
    border: 1px solid #fff; }
    .young-drivers-signup .btn-primary:hover, .young-drivers-signup .btn-primary:active, .young-drivers-signup .btn-primary:focus {
      background-color: transparent;
      border: 1px solid #fff; }

.well {
  padding: 50px 0;
  margin-bottom: 0;
  box-shadow: none;
  border: none;
  border-radius: 0px; }
  .well h1 {
    margin-top: 0;
    margin-bottom: 10px; }
  @media (max-width: 768px) {
    .well {
      padding: 30px 0; } }

.well-primary {
  margin-top: 0;
  padding-bottom: 50px;
  background-color: #4EE625; }
  .well-primary .copy {
    padding-top: 57px;
    color: #fff;
    text-align: center;
    font-size: 22px; }
    @media (max-width: 768px) {
      .well-primary .copy {
        font-size: 14px;
        padding-top: 31px; } }
  @media (max-width: 768px) {
    .well-primary {
      padding-bottom: 30px; } }

.well-secondary {
  padding: 75px 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8; }
  @media (max-width: 991px) {
    .well-secondary {
      padding: 40px 0; } }
  @media (max-width: 768px) {
    .well-secondary .products-panel-group {
      margin-bottom: 5px; } }

.well-elevator-pitch {
  padding-top: 0;
  background-color: #4E9CCA; }
  .well-elevator-pitch .btn-primary {
    background-color: #4EE625;
    border-color: #4EE625; }
    .well-elevator-pitch .btn-primary:hover, .well-elevator-pitch .btn-primary:focus, .well-elevator-pitch .btn-primary:active {
      background-color: #3bc216;
      border-color: #3bc216; }
  @media (max-width: 991px) {
    .well-elevator-pitch .copy {
      font-size: 18px; } }

.well-default {
  background: #ffffff;
  padding-bottom: 10px; }
  .well-default .heading {
    font-family: Gotham Bold;
    color: #434343;
    font-size: 50px; }
    @media (max-width: 768px) {
      .well-default .heading {
        font-size: 22px; } }
  .well-default .copy {
    padding: 0;
    text-align: left;
    color: #434343; }
  .well-default .wysiwyg {
    margin-top: 40px; }

.well-young {
  background: #25ABF0 url("/images/bg-youngdrivers.png") center bottom no-repeat; }
  @media (max-width: 768px) {
    .well-young {
      background: #25ABF0 center bottom no-repeat; } }
  .well-young .heading {
    font-family: Gotham Bold;
    color: #fff;
    font-size: 50px; }
    @media (max-width: 768px) {
      .well-young .heading {
        font-size: 28px; } }
  .well-young .copy {
    padding: 0;
    text-align: left; }

.mobile-young-drivers-image {
  display: none;
  background: url("/images/mobile-young-drivers.png") center bottom no-repeat;
  min-height: 285px;
  background-size: cover; }
  @media (max-width: 768px) {
    .mobile-young-drivers-image {
      display: block; } }

.well-content {
  background: #f5f5f5; }
  .well-content .heading {
    font-family: Gotham Bold;
    color: #434343;
    font-size: 50px; }
    @media (max-width: 768px) {
      .well-content .heading {
        font-size: 28px; } }
  .well-content .copy {
    padding: 0;
    text-align: left;
    color: #434343; }

.chapter-heading {
  font-size: 30px;
  color: #434343; }
  @media (max-width: 991px) {
    .chapter-heading {
      font-size: 24px;
      margin-bottom: 20px; } }

.chapter-subheading {
  padding-bottom: 35px;
  font-size: 15px;
  color: #6e6e6e;
  text-align: center; }
  @media (max-width: 991px) {
    .chapter-subheading {
      display: none; } }

.thumbnail-products {
  border-radius: 0;
  padding: 0;
  min-height: 520px; }
  @media (max-width: 991px) {
    .thumbnail-products {
      min-height: 420px; } }
  @media (max-width: 991px) {
    .thumbnail-products .read-more-icon {
      right: 19px; } }
  .thumbnail-products:hover, .thumbnail-products:active, .thumbnail-products:focus {
    text-decoration: none;
    border-color: #ddd !important; }
    .thumbnail-products:hover .read-more-icon, .thumbnail-products:active .read-more-icon, .thumbnail-products:focus .read-more-icon {
      transition: width 0.1s;
      text-decoration: none;
      width: 210px;
      line-height: 32px;
      border-radius: 25px; }
      @media (min-width: 1200px) {
        .thumbnail-products:hover .read-more-icon, .thumbnail-products:active .read-more-icon, .thumbnail-products:focus .read-more-icon {
          width: 180px; } }
      @media (max-width: 991px) {
        .thumbnail-products:hover .read-more-icon, .thumbnail-products:active .read-more-icon, .thumbnail-products:focus .read-more-icon {
          width: 180px; } }
      .thumbnail-products:hover .read-more-icon .closed, .thumbnail-products:active .read-more-icon .closed, .thumbnail-products:focus .read-more-icon .closed {
        display: none; }
      .thumbnail-products:hover .read-more-icon .opened, .thumbnail-products:active .read-more-icon .opened, .thumbnail-products:focus .read-more-icon .opened {
        display: inline; }
      .thumbnail-products:hover .read-more-icon .opened-chevron, .thumbnail-products:active .read-more-icon .opened-chevron, .thumbnail-products:focus .read-more-icon .opened-chevron {
        display: block; }
  @media (max-width: 768px) {
    .thumbnail-products .image-container {
      display: block;
      max-height: 175px;
      overflow: hidden; } }
  .thumbnail-products .thumb-image {
    width: 100%; }
    @media (max-width: 768px) {
      .thumbnail-products .thumb-image.home-mobile {
        margin-top: -100px;
        vertical-align: middle; } }
    @media (max-width: 480px) {
      .thumbnail-products .thumb-image.home-mobile {
        margin-top: 0;
        vertical-align: middle; } }
    @media (max-width: 768px) {
      .thumbnail-products .thumb-image.home-desktop {
        display: none; } }
    .thumbnail-products .thumb-image.home-mobile {
      display: none; }
      @media (max-width: 768px) {
        .thumbnail-products .thumb-image.home-mobile {
          display: inline; } }
  .thumbnail-products .caption {
    position: relative;
    text-align: center;
    padding: 40px 30px 30px 30px;
    min-height: 220px; }
    @media (max-width: 991px) {
      .thumbnail-products .caption {
        padding: 40px 15px 30px 15px; } }
    .thumbnail-products .caption .sub-heading {
      display: block;
      color: #d4d4d4;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase; }
    .thumbnail-products .caption .heading {
      display: block;
      font-size: 27px; }
      @media (max-width: 991px) {
        .thumbnail-products .caption .heading {
          font-size: 24px; } }
    .thumbnail-products .caption .copy {
      display: block;
      font-size: 15px;
      color: #6d6d6d; }
    .thumbnail-products .caption .icon-container {
      padding-top: 15px;
      height: 66px; }

.thumbnail-young {
  min-height: 548px; }
  @media (max-width: 768px) {
    .thumbnail-young {
      min-height: 515px; } }
  .thumbnail-young .icon-container {
    padding-top: 55px;
    display: block;
    background-color: #25ABF0;
    width: 215px;
    height: 215px;
    border-radius: 50%;
    overflow: hidden; }
    .thumbnail-young .icon-container img {
      display: block;
      margin: 0 auto; }
    @media (max-width: 991px) {
      .thumbnail-young .icon-container {
        padding-top: 40px;
        width: 150px;
        height: 150px; } }
    @media (max-width: 768px) {
      .thumbnail-young .icon-container {
        padding-top: 55px;
        width: 215px;
        height: 215px; } }
  .thumbnail-young .img-container {
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    text-align: center;
    width: 215px;
    height: 215px; }
    @media (max-width: 991px) {
      .thumbnail-young .img-container {
        width: 150px;
        height: 150px; }
        .thumbnail-young .img-container img {
          height: 70px; } }
    @media (max-width: 768px) {
      .thumbnail-young .img-container {
        width: 215px;
        height: 215px; }
        .thumbnail-young .img-container img {
          height: auto; } }
  .thumbnail-young .step-badge {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: #25ABF0;
    color: white;
    font-size: 18px;
    line-height: 39px;
    text-align: center; }
    @media (max-width: 991px) {
      .thumbnail-young .step-badge {
        right: 0px;
        bottom: 0px; } }
    @media (max-width: 768px) {
      .thumbnail-young .step-badge {
        right: 10px;
        bottom: 10px; } }
  .thumbnail-young .heading {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 27px;
    color: #25ABF0;
    background: transparent url("/images/breaker-sidebar.png") center bottom no-repeat !important; }
    @media (max-width: 768px) {
      .thumbnail-young .heading {
        font-size: 27px;
        padding-bottom: 15px;
        margin-bottom: 15px; } }

.cw50 {
  float: left;
  width: 50%;
  min-height: 350px; }
  @media (max-width: 768px) {
    .cw50 {
      width: 100%; } }
  .cw50.image {
    position: relative;
    background-size: cover; }
    @media (max-width: 991px) {
      .cw50.image {
        background-position: right; } }
    @media (max-width: 768px) {
      .cw50.image {
        background-size: 130% auto;
        min-height: 240px; } }
    .cw50.image .read-more-icon {
      background-color: #4EE625; }
      @media (max-width: 768px) {
        .cw50.image .read-more-icon {
          width: 210px;
          line-height: 32px;
          top: auto !important;
          left: 0 !important;
          right: 0 !important;
          bottom: -21px;
          margin: 0 auto; }
          .cw50.image .read-more-icon .closed {
            display: none; }
          .cw50.image .read-more-icon .opened {
            display: inline; }
          .cw50.image .read-more-icon .opened-chevron {
            display: block; } }
    .cw50.image:hover, .cw50.image:active, .cw50.image:focus {
      text-decoration: none;
      border-color: #ddd !important; }
      .cw50.image:hover .read-more-icon, .cw50.image:active .read-more-icon, .cw50.image:focus .read-more-icon {
        transition: width 0.1s;
        text-decoration: none;
        width: 210px;
        line-height: 32px;
        border-radius: 25px; }
        .cw50.image:hover .read-more-icon .closed, .cw50.image:active .read-more-icon .closed, .cw50.image:focus .read-more-icon .closed {
          display: none; }
        .cw50.image:hover .read-more-icon .opened, .cw50.image:active .read-more-icon .opened, .cw50.image:focus .read-more-icon .opened {
          display: inline; }
        .cw50.image:hover .read-more-icon .opened-chevron, .cw50.image:active .read-more-icon .opened-chevron, .cw50.image:focus .read-more-icon .opened-chevron {
          display: block; }
  .cw50.left .read-more-icon {
    top: 45%;
    right: -20px; }
  .cw50.right .read-more-icon {
    top: 45%;
    left: -20px; }
  .cw50.dark.copy {
    background-color: #25ABF0; }
    .cw50.dark.copy .heading {
      color: white; }
    .cw50.dark.copy .sub-heading {
      color: white; }
    .cw50.dark.copy ul {
      color: white; }
  .cw50.light.copy {
    background-color: #4EE625; }
    .cw50.light.copy .heading {
      color: white; }
    .cw50.light.copy .sub-heading {
      color: white; }
    .cw50.light.copy ul {
      color: white; }
  .cw50.copy {
    padding: 50px;
    background-color: #fff; }
    @media (max-width: 991px) {
      .cw50.copy {
        padding: 30px; } }
    @media (max-width: 768px) {
      .cw50.copy {
        padding: 60px 30px 30px 30px; } }
    .cw50.copy .heading {
      color: #2f2f2f;
      font-size: 30px; }
    .cw50.copy .sub-heading {
      color: #2f2f2f;
      font-size: 15px; }
    .cw50.copy ul {
      display: initial;
      position: relative;
      padding-left: 0px;
      margin-bottom: 20px;
      font-size: 15px;
      color: #6d6d6d;
      font-family: "Varela Round", sans-serif;
      list-style: none; }
      .cw50.copy ul li {
        padding-left: 13px;
        margin-bottom: 10px; }
        .cw50.copy ul li:last-child {
          margin-bottom: 0; }
      .cw50.copy ul li:before {
        position: absolute;
        font-size: 16px;
        left: 0px;
        content: "\2022";
        color: #4EE625; }

.tablet-cw50-image {
  display: none; }
  @media (max-width: 768px) {
    .tablet-cw50-image {
      display: block; } }

@media (max-width: 768px) {
  .desktop-cw50-image {
    display: none; } }

.next-page-link {
  margin-top: 12px;
  font-size: 13px;
  color: #25ABF0;
  text-transform: uppercase;
  font-weight: 800; }
  .next-page-link .icon {
    margin-left: 11px;
    font-size: 12px; }
  .next-page-link:hover, .next-page-link:active, .next-page-link:focus {
    text-decoration: none;
    color: #0f91d3; }

.thumbnail-blog {
  margin-top: 15px;
  border-radius: 0;
  padding: 0;
  min-height: 463px; }
  @media (max-width: 991px) {
    .thumbnail-blog {
      min-height: 481px; } }
  .thumbnail-blog .thumb-image {
    width: 100%;
    min-height: 157px; }
  .thumbnail-blog:hover, .thumbnail-blog:active, .thumbnail-blog:focus {
    text-decoration: none;
    border-color: #25ABF0 !important; }
  .thumbnail-blog .btn-primary {
    bottom: 31px; }
  .thumbnail-blog .caption {
    padding: 18px 26px; }
    .thumbnail-blog .caption .title {
      font-size: 18px;
      color: #434343; }
    .thumbnail-blog .caption .timestamp {
      margin-top: 18px;
      margin-bottom: 10px;
      padding-top: 18px;
      font-size: 13px;
      color: #bcbcbc;
      text-transform: uppercase;
      background: transparent url("/images/timestamp-border.png") left top no-repeat; }
      .thumbnail-blog .caption .timestamp img {
        margin-top: -2px; }
    .thumbnail-blog .caption .copy {
      color: #6d6d6d;
      font-size: 15px;
      margin-bottom: 10px; }
    .thumbnail-blog .caption .quote-container {
      margin-bottom: 38px;
      position: relative;
      padding: 30px 28px;
      color: #6d6d6d;
      font-size: 15px;
      border: 1px solid #dfdfdf;
      background-image: url("/images/icon-quote-left.svg"), url("/images/icon-quote-right.svg");
      background-repeat: no-repeat, no-repeat;
      background-position: 10% 10%, 90% 90%; }
      .thumbnail-blog .caption .quote-container:after {
        bottom: -25px;
        left: -1px;
        position: absolute;
        content: "";
        width: 23px;
        height: 25px;
        background: transparent url("/images/quote-bubble-leg.png") left top no-repeat; }
    .thumbnail-blog .caption .cite-container .avatar {
      margin-right: 15px;
      width: 51px;
      height: 51px;
      border-radius: 45px;
      overflow: hidden; }
    .thumbnail-blog .caption .cite-container .name {
      color: #434343;
      font-size: 23px; }
    .thumbnail-blog .caption .cite-container .honours-title {
      font-size: 13px;
      color: #bcbcbc;
      font-style: italic; }

.error-page {
  text-align: center; }
  .error-page .heading {
    margin: 0 auto;
    margin-top: 100px;
    width: 228px;
    color: #4E9CCA;
    font-size: 122px;
    font-family: "Varela Round", sans-serif;
    font-weight: 800;
    border-bottom: 6px solid #4E9CCA; }
  .error-page .subheading {
    margin-top: 20px;
    font-size: 13px; }
  .error-page .simp {
    margin-bottom: 100px; }

@media (max-width: 768px) {
  .pagination {
    width: 100%;
    margin-bottom: 0;
    margin-top: 0; } }

.pagination li a, .pagination li span {
  padding: 9px 15px 8px 15px;
  margin-left: 5px;
  margin-right: 5px;
  color: #4EE625;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  border-color: #4EE625;
  border-radius: 2px; }
  .pagination li a:hover, .pagination li a:active, .pagination li a:focus, .pagination li span:hover, .pagination li span:active, .pagination li span:focus {
    border-color: #4EE625;
    background-color: #4EE625;
    color: white; }
  @media (max-width: 768px) {
    .pagination li a, .pagination li span {
      display: none;
      margin-left: 0;
      margin-right: 0; } }

.pagination li:first-child a, .pagination li:first-child span {
  position: relative;
  text-align: center;
  padding: 0px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  width: 41px;
  height: 40px;
  color: white; }
  .pagination li:first-child a:before, .pagination li:first-child span:before {
    content: "\f053";
    position: absolute;
    font: normal normal normal 14px/1 FontAwesome;
    top: 13px;
    left: 13px;
    color: #4EE625; }
    @media (max-width: 768px) {
      .pagination li:first-child a:before, .pagination li:first-child span:before {
        top: 19px;
        color: white; } }
  .pagination li:first-child a:hover, .pagination li:first-child a:focus, .pagination li:first-child a:active, .pagination li:first-child span:hover, .pagination li:first-child span:focus, .pagination li:first-child span:active {
    color: transparent; }
    .pagination li:first-child a:hover:before, .pagination li:first-child a:focus:before, .pagination li:first-child a:active:before, .pagination li:first-child span:hover:before, .pagination li:first-child span:focus:before, .pagination li:first-child span:active:before {
      color: white; }
  @media (max-width: 768px) {
    .pagination li:first-child a, .pagination li:first-child span {
      display: block;
      width: 50%;
      height: 55px;
      background-color: #4EE625;
      color: #4EE625;
      border-radius: 0;
      border-color: transparent; }
      .pagination li:first-child a:after, .pagination li:first-child span:after {
        content: "Previous";
        color: #fff;
        font-size: 20px;
        text-transform: capitalize;
        line-height: 52px; } }

.pagination li:last-child a, .pagination li:last-child span {
  position: relative;
  text-align: center;
  padding: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 41px;
  height: 40px;
  color: white; }
  .pagination li:last-child a:before, .pagination li:last-child span:before {
    content: "\f054";
    position: absolute;
    font: normal normal normal 14px/1 FontAwesome;
    top: 13px;
    right: 13px;
    color: #4EE625; }
    @media (max-width: 768px) {
      .pagination li:last-child a:before, .pagination li:last-child span:before {
        top: 19px;
        color: white; } }
  .pagination li:last-child a:hover, .pagination li:last-child a:focus, .pagination li:last-child a:active, .pagination li:last-child span:hover, .pagination li:last-child span:focus, .pagination li:last-child span:active {
    color: transparent; }
    .pagination li:last-child a:hover:before, .pagination li:last-child a:focus:before, .pagination li:last-child a:active:before, .pagination li:last-child span:hover:before, .pagination li:last-child span:focus:before, .pagination li:last-child span:active:before {
      color: white; }
  @media (max-width: 768px) {
    .pagination li:last-child a, .pagination li:last-child span {
      display: block;
      width: 50%;
      height: 55px;
      background-color: #25ABF0;
      color: #25ABF0;
      border-radius: 0;
      border-color: transparent; }
      .pagination li:last-child a:after, .pagination li:last-child span:after {
        content: "Next";
        color: #fff;
        font-size: 20px;
        text-transform: capitalize;
        line-height: 52px; } }

@media (max-width: 768px) {
  .pagination li.disabled a, .pagination li.disabled span {
    color: #C1C1C1; } }

.pagination li.disabled a:before, .pagination li.disabled span:before {
  color: #ddd; }
  @media (max-width: 768px) {
    .pagination li.disabled a:before, .pagination li.disabled span:before {
      color: #fff; } }

.pagination li.disabled a:hover, .pagination li.disabled a:active, .pagination li.disabled a:focus, .pagination li.disabled span:hover, .pagination li.disabled span:active, .pagination li.disabled span:focus {
  color: transparent;
  border-color: transparent; }
  .pagination li.disabled a:hover:before, .pagination li.disabled a:active:before, .pagination li.disabled a:focus:before, .pagination li.disabled span:hover:before, .pagination li.disabled span:active:before, .pagination li.disabled span:focus:before {
    color: #ddd; }
    @media (max-width: 768px) {
      .pagination li.disabled a:hover:before, .pagination li.disabled a:active:before, .pagination li.disabled a:focus:before, .pagination li.disabled span:hover:before, .pagination li.disabled span:active:before, .pagination li.disabled span:focus:before {
        color: #fff; } }
  @media (max-width: 768px) {
    .pagination li.disabled a:hover, .pagination li.disabled a:active, .pagination li.disabled a:focus, .pagination li.disabled span:hover, .pagination li.disabled span:active, .pagination li.disabled span:focus {
      background-color: #C1C1C1; }
      .pagination li.disabled a:hover:after, .pagination li.disabled a:active:after, .pagination li.disabled a:focus:after, .pagination li.disabled span:hover:after, .pagination li.disabled span:active:after, .pagination li.disabled span:focus:after {
        color: white; } }

@media (max-width: 768px) {
  .pagination li.disabled a, .pagination li.disabled span {
    background-color: #C1C1C1;
    border-radius: 0;
    border-color: transparent; } }

.pagination li.active span {
  background-color: #4EE625;
  border-color: #4EE625;
  color: #fff; }
  .pagination li.active span:hover, .pagination li.active span:focus, .pagination li.active span:active {
    background-color: #3bc216;
    border-color: #3bc216; }

section.content-template .header {
  text-align: left; }
  section.content-template .header h1 {
    font-weight: 800;
    margin-bottom: 20px; }

section.content-template .sub-heading {
  font-size: 22px;
  color: #434343; }

section.content-template .post-data {
  text-align: center; }

.thumb-gallery {
  margin-bottom: 40px; }
  @media (max-width: 768px) {
    .thumb-gallery {
      margin-bottom: 0; } }
  .thumb-gallery .cw50 {
    min-height: 485px; }
    @media (max-width: 768px) {
      .thumb-gallery .cw50 {
        min-height: 300px; } }
  @media (max-width: 768px) {
    .thumb-gallery .copy {
      padding: 25px 15px 25px 15px; } }
  .thumb-gallery .heading {
    margin-bottom: 30px;
    padding-bottom: 25px;
    font-size: 30px;
    font-family: Gotham Bold;
    text-transform: uppercase;
    background-image: url("/images/breaker-products.png");
    background-position: left bottom;
    background-repeat: no-repeat; }
  @media (max-width: 768px) {
    .thumb-gallery .copy .heading {
      font-size: 22px;
      margin-bottom: 18px;
      padding-bottom: 17px; } }
  .thumb-gallery .sub-heading {
    font-size: 15px;
    font-family: Gotham Book; }
  .thumb-gallery .btn-secondary {
    margin-top: 30px; }

.page-heading {
  padding: 35px 0;
  text-align: center; }
  .page-heading .heading {
    font-size: 30px;
    color: #434343; }
  .page-heading .copy {
    font-size: 18px;
    color: #6e6e6e; }

.js-social-follower {
  width: 262px; }
  .js-social-follower .social-icons {
    margin-top: 0 !important; }

.js-stick {
  transition: all 0.1s;
  position: fixed;
  top: 102px;
  width: 262px !important; }

.link-caption .timestamp {
  margin-top: 0 !important; }

.download-home, .link-home {
  position: relative; }
  .download-home .download-container, .link-home .download-container {
    position: relative;
    text-align: center;
    margin: 20px 20px 0 20px;
    min-height: 110px;
    padding: 105px 20px 30px 20px;
    color: #6d6d6d;
    font-size: 18px;
    border: 1px solid #dfdfdf; }
    .download-home .download-container:before, .link-home .download-container:before {
      position: absolute;
      top: 25px;
      left: 0px;
      right: 0;
      margin: 0 auto;
      width: 58px;
      height: 58px;
      content: "";
      background-size: 58px; }
  .download-home a:hover, .download-home a:active, .download-home a:focus, .link-home a:hover, .link-home a:active, .link-home a:focus {
    text-decoration: none; }
    .download-home a:hover .download-container, .download-home a:active .download-container, .download-home a:focus .download-container, .link-home a:hover .download-container, .link-home a:active .download-container, .link-home a:focus .download-container {
      background-color: #f5f5f5; }
  .download-home .post-data, .link-home .post-data {
    background: none;
    margin-top: 0; }

.download-home .download-container:before {
  background: transparent url("/images/icon-download.png") center top no-repeat;
  background-size: 58px; }

.link-home .download-container:before {
  background: transparent url("/images/icon-link.png") center top no-repeat;
  background-size: 58px; }

.alert {
  margin-top: 20px; }

.home-alert-wrapper {
  position: absolute;
  width: 100%;
  text-align: center; }
  .home-alert-wrapper .alert {
    margin-top: 0; }

.products-mobile {
  display: none;
  padding-bottom: 20px; }
  @media (max-width: 768px) {
    .products-mobile {
      display: block; } }

@media (max-width: 768px) {
  .products-desktop {
    display: none; } }

.products-panel-group {
  margin-bottom: 50px; }
  .products-panel-group .panel-default {
    margin-bottom: -6px !important; }
    .products-panel-group .panel-default:first-of-type .panel-heading {
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .products-panel-group .panel-default:last-of-type .panel-heading {
      -webkit-border-bottom-right-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-bottomright: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px; }
  .products-panel-group .panel-heading {
    padding: 0;
    background-color: #fff;
    border-radius: 0px; }
  .products-panel-group .panel-title {
    position: relative;
    font-size: 21px;
    color: #434343; }
    .products-panel-group .panel-title a {
      padding: 30px 18px 30px 59px;
      text-align: center;
      display: block; }
      .products-panel-group .panel-title a:hover, .products-panel-group .panel-title a:active, .products-panel-group .panel-title a:focus {
        text-decoration: none; }
    .products-panel-group .panel-title .icon {
      left: 15px;
      top: 27px;
      position: absolute; }
    .products-panel-group .panel-title .chevron {
      transition: all 0.1s;
      margin-top: 8px;
      float: right; }
  .products-panel-group .panel-body {
    background-color: #f5f5f5;
    color: #4d4d4d;
    font-size: 18px;
    text-align: center; }
  .products-panel-group .open .chevron {
    transition: all 0.1s;
    /* IE 9 */
    /* Chrome, Safari, Opera */
    transform: rotate(180 deg); }
  .products-panel-group .btn-primary {
    margin-top: 20px;
    width: 100%; }
  .products-panel-group .step-badge {
    position: absolute;
    top: 19px;
    left: 15px;
    line-height: 46px;
    width: 45px;
    height: 45px;
    background-color: #25ABF0;
    color: white;
    border-radius: 50%; }

@media (max-width: 768px) {
  .gal-container {
    padding-bottom: 0; } }

@media (max-width: 768px) {
  .young-drivers-page-heading {
    display: none; } }

@media (max-width: 768px) {
  .young-well-secondary {
    padding-bottom: 0px; } }

@media (max-width: 768px) {
  .paginaton-container {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -27px; } }

.contains-link:hover, .contains-link:focus, .contains-link:active {
  border-color: #25ABF0 !important; }

.wysiwyg {
  color: #6d6d6d;
  font-family: Gotham Book; }
  .wysiwyg p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px; }
    @media (max-width: 768px) {
      .wysiwyg p {
        font-size: 15px; } }
  .wysiwyg h2 {
    margin: 40px 0 15px 0;
    font-size: 30px;
    color: #25ABF0; }
    @media (max-width: 768px) {
      .wysiwyg h2 {
        font-size: 21px;
        margin: 35px 0; }
        .wysiwyg h2:first-of-type {
          margin-top: 0; } }
  .wysiwyg h3 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 25px;
    color: #4EE625; }
    @media (max-width: 768px) {
      .wysiwyg h3 {
        font-size: 18px; }
        .wysiwyg h3:first-of-type {
          margin-top: 0; } }
  .wysiwyg ul {
    position: relative;
    padding-left: 0px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #6d6d6d;
    font-family: Gotham Book;
    list-style: none; }
    @media (max-width: 768px) {
      .wysiwyg ul {
        font-size: 15px; } }
    .wysiwyg ul li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        .wysiwyg ul li {
          padding-left: 15px; } }
    .wysiwyg ul li:before {
      position: absolute;
      left: 0px;
      line-height: 22px;
      font-size: 30px;
      content: "\2022";
      color: #4EE625; }
      @media (max-width: 768px) {
        .wysiwyg ul li:before {
          left: -1px; } }
  .wysiwyg ol {
    position: relative;
    padding-left: 0px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #6d6d6d;
    font-family: Gotham Book;
    list-style: none;
    counter-reset: ol-counter; }
    @media (max-width: 768px) {
      .wysiwyg ol {
        font-size: 15px; } }
    .wysiwyg ol li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        .wysiwyg ol li {
          padding-left: 15px; } }
    .wysiwyg ol li:before {
      position: absolute;
      left: 0px;
      top: 2px;
      font-size: 16px;
      content: counter(ol-counter);
      counter-increment: ol-counter;
      color: #4EE625; }
  .wysiwyg .alignleft, .wysiwyg img[style*="float:left;"], .wysiwyg img[style*="float: left;"] {
    float: left;
    margin: 0px 30px 30px 0px; }
  .wysiwyg .alignright, .wysiwyg img[style*="float:right;"], .wysiwyg img[style*="float: right;"] {
    float: right;
    margin: 0px 0px 30px 30px; }
  .wysiwyg .aligncenter, .wysiwyg img.aligncenter {
    margin: 30px;
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto; }

.sidebar .sidebar-share {
  width: 100%;
  margin-bottom: 20px;
  padding: 30px 25px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 400;
  background-color: #25ABF0; }
  @media (max-width: 768px) {
    .sidebar .sidebar-share {
      display: none; } }
  .sidebar .sidebar-share .social-icons {
    margin: 0 auto;
    margin-top: 16px;
    width: 159px;
    list-style-type: none;
    padding-left: 0; }
    .sidebar .sidebar-share .social-icons li {
      float: left;
      padding-right: 13px; }
      .sidebar .sidebar-share .social-icons li:last-child {
        padding-right: 0; }
    .sidebar .sidebar-share .social-icons a {
      padding-top: 2px;
      text-align: center;
      display: block;
      width: 30px;
      height: 30px;
      border: 1px solid #fff;
      border-radius: 2px;
      color: #fff; }

.sidebar .share-mobile {
  display: none;
  margin-bottom: 0;
  margin-top: 30px; }
  .sidebar .share-mobile .sidebar-share {
    display: block !important; }
  @media (max-width: 768px) {
    .sidebar .share-mobile {
      display: block; } }

.sidebar .sidebar-newsletter {
  text-align: center;
  padding: 20px 24px; }
  @media (max-width: 768px) {
    .sidebar .sidebar-newsletter {
      display: none; } }
  .sidebar .sidebar-newsletter .heading {
    font-size: 18px;
    color: #25ABF0; }
  .sidebar .sidebar-newsletter .subheading {
    padding-bottom: 16px;
    margin-bottom: 16px;
    font-size: 12px;
    color: #6d6d6d;
    background: transparent url("/images/breaker.png") center bottom no-repeat; }
  .sidebar .sidebar-newsletter .form-group {
    margin-bottom: 8px; }
  .sidebar .sidebar-newsletter .form-control {
    text-align: center;
    height: 36px;
    border: 1px solid #d3d3d3; }
  .sidebar .sidebar-newsletter .btn-primary {
    margin-bottom: 8px;
    font-size: 12px;
    width: 100%;
    padding: 9px 29px; }
  .sidebar .sidebar-newsletter .link {
    font-size: 12px; }

@media (max-width: 768px) {
  .sidebar .sidebar-catergory {
    display: none; } }

.sidebar .sidebar-catergory .top-half {
  padding: 20px 25px;
  border-bottom: 1px solid #d3d3d3; }

.sidebar .sidebar-catergory .bottom-half {
  padding: 20px 25px; }

.sidebar .sidebar-catergory .heading {
  margin-bottom: 10px;
  text-align: center;
  color: #434343;
  font-size: 27px; }

.sidebar .sidebar-catergory .subheading {
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  color: #d4d4d4;
  font-size: 12px; }

.sidebar .sidebar-catergory .icon {
  margin: 0 auto; }

.sidebar .sidebar-catergory .category-listing {
  color: #6d6d6d;
  font-size: 15px;
  list-style-type: none;
  padding-left: 0;
  line-height: 22px; }
  .sidebar .sidebar-catergory .category-listing li {
    padding-left: 14px;
    margin-bottom: 10px;
    background: transparent url("/images/icon-list-chevron-right.svg") left 48% no-repeat; }
  .sidebar .sidebar-catergory .category-listing a {
    color: #6d6d6d; }
    .sidebar .sidebar-catergory .category-listing a:hover {
      color: #25ABF0;
      text-decoration: none; }

.sidebar .rss-feed {
  font-size: 15px;
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 20px; }
  @media (max-width: 768px) {
    .sidebar .rss-feed {
      display: none; } }

.sidebar .sidebar-item {
  border: 1px solid #d3d3d3;
  margin-bottom: 20px; }

@media (max-width: 768px) {
  .sidebar .sidebar-preview {
    display: none; } }

.sidebar .sidebar-preview .heading {
  padding: 20px 25px;
  font-weight: 800;
  color: #25ABF0;
  font-size: 18px;
  text-align: center; }

.sidebar .sidebar-preview .article-title {
  font-size: 12px;
  color: #25ABF0; }

.sidebar .sidebar-preview .excerpt {
  font-size: 12px;
  color: #434343; }

.sidebar .sidebar-preview .time-stamp {
  padding-top: 10px;
  margin-top: 10px;
  background: transparent url("/images/breaker-sidebar.png") left top no-repeat;
  color: #434343;
  font-size: 11px; }
  .sidebar .sidebar-preview .time-stamp .icon {
    margin-top: -2px;
    width: 10px; }

.sidebar .sidebar-preview .img-container {
  height: 108px;
  overflow: hidden; }

.sidebar .sidebar-preview .copy {
  padding: 20px 25px; }

.sidebar .sidebar-preview:hover, .sidebar .sidebar-preview:active, .sidebar .sidebar-preview:focus {
  text-decoration: none; }

@media (max-width: 768px) {
  .sidebar .sidebar-contact {
    display: none; } }

.sidebar .sidebar-contact .subheading {
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  color: #d4d4d4;
  font-size: 12px; }
  @media (max-width: 768px) {
    .sidebar .sidebar-contact .subheading {
      font-size: 17px; } }

.sidebar .sidebar-contact .heading {
  margin-top: 20px;
  text-align: center;
  font-size: 27px;
  color: #434343; }
  @media (max-width: 768px) {
    .sidebar .sidebar-contact .heading {
      font-size: 38px; } }

.sidebar .sidebar-contact .icon {
  display: block;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .sidebar .sidebar-contact .icon {
      width: 36px; } }

.sidebar .sidebar-contact .location {
  font-family: Gotham Book;
  padding: 35px 20px 15px 20px;
  margin-top: 20px;
  border-top: 1px solid #d3d3d3;
  color: #434343;
  font-size: 13px; }
  @media (max-width: 991px) {
    .sidebar .sidebar-contact .location a, .sidebar .sidebar-contact .location strong {
      display: block;
      width: 250px;
      margin: 20px auto 0 auto; } }
  @media (max-width: 768px) {
    .sidebar .sidebar-contact .location {
      font-size: 17px; } }

.sidebar .sidebar-contact .table-sidebar {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 13px; }
  @media (max-width: 768px) {
    .sidebar .sidebar-contact .table-sidebar {
      width: 250px;
      margin: 0px auto 40px auto; } }
  .sidebar .sidebar-contact .table-sidebar td {
    padding: 1px;
    border: none; }
    @media (max-width: 768px) {
      .sidebar .sidebar-contact .table-sidebar td {
        font-size: 17px; } }

.sidebar .sidebar-insurance-tile {
  padding: 25px 20px; }
  .sidebar .sidebar-insurance-tile .btn-primary {
    margin-top: 20px;
    font-size: 12px;
    width: 100%;
    padding: 9px 29px; }
    @media (max-width: 768px) {
      .sidebar .sidebar-insurance-tile .btn-primary {
        font-size: 16px; } }
  .sidebar .sidebar-insurance-tile .caption {
    position: relative;
    text-align: center; }
    @media (max-width: 991px) {
      .sidebar .sidebar-insurance-tile .caption {
        padding: 40px 15px 30px 15px; } }
    @media (max-width: 768px) {
      .sidebar .sidebar-insurance-tile .caption {
        padding: 0px 15px 0 15px; } }
    .sidebar .sidebar-insurance-tile .caption .sub-heading {
      display: block;
      color: #d4d4d4;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase; }
    .sidebar .sidebar-insurance-tile .caption .heading {
      display: block;
      font-size: 27px; }
      @media (max-width: 991px) {
        .sidebar .sidebar-insurance-tile .caption .heading {
          font-size: 24px; } }
      @media (max-width: 768px) {
        .sidebar .sidebar-insurance-tile .caption .heading {
          margin-bottom: 5px; } }
    .sidebar .sidebar-insurance-tile .caption .copy {
      display: block;
      font-size: 15px;
      color: #6d6d6d; }
      @media (max-width: 768px) {
        .sidebar .sidebar-insurance-tile .caption .copy {
          font-size: 17px; } }
    .sidebar .sidebar-insurance-tile .caption .icon-container {
      padding-top: 15px;
      height: 66px; }
      @media (max-width: 768px) {
        .sidebar .sidebar-insurance-tile .caption .icon-container {
          display: none; } }

#map {
  height: 215px; }

section.contact {
  padding: 55px 0 85px 0; }
  section.contact .btn-quad {
    margin-top: 8px; }
  section.contact .label {
    position: relative;
    top: -1px;
    padding: 5px 4px 5px 4px; }

.well-contact {
  position: relative;
  overflow: hidden;
  background-color: #4EE625; }
  @media (max-width: 768px) {
    .well-contact {
      background: #4EE625; } }
  .well-contact .heading {
    font-family: Gotham Bold;
    color: #fff;
    font-size: 50px; }
    @media (max-width: 768px) {
      .well-contact .heading {
        font-size: 28px; } }
  .well-contact .copy {
    padding: 0;
    text-align: left; }

.form-control {
  height: 43px;
  border: 1px solid #d9d9d9;
  box-shadow: none; }

.form-group {
  margin-bottom: 20px; }

.form-group label {
  color: #535353;
  font-family: Gotham Book;
  font-size: 14px; }

.contact-sidebar {
  border: 1px solid #e6e6e6; }
  @media (max-width: 991px) {
    .contact-sidebar {
      margin-top: 30px; } }
  @media (max-width: 768px) {
    .contact-sidebar {
      display: none; } }
  .contact-sidebar .copy-container {
    padding: 50px 30px 30px 30px; }
  .contact-sidebar .heading {
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
    font-family: Gotham Bold;
    color: #25ABF0; }
  .contact-sidebar .sub-heading {
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
    font-family: Gotham Bold;
    font-family: #434343; }
  .contact-sidebar .address {
    position: relative;
    padding-bottom: 30px;
    margin: 0 auto;
    width: 100%;
    padding-left: 37px;
    font-size: 16px;
    color: #434343;
    font-family: Gotham Book; }
    .contact-sidebar .address .icon {
      position: absolute;
      left: 0;
      top: 0; }
      .contact-sidebar .address .icon svg {
        fill: #4EE625; }
    .contact-sidebar .address:hover, .contact-sidebar .address:focus, .contact-sidebar .address:active {
      text-decoration: none; }
    @media (min-width: 1200px) {
      .contact-sidebar .address {
        width: 268px; } }
  @media (max-width: 991px) {
    .contact-sidebar .table {
      width: 450px;
      margin: 0 auto; } }
  .contact-sidebar .table-striped tr td {
    padding-left: 30px;
    border: none;
    font-size: 14px;
    font-family: Gotham Book;
    color: #434343; }
    .contact-sidebar .table-striped tr td:first-child {
      padding-left: 25px;
      color: #25ABF0;
      font-family: Gotham Bold; }
  .contact-sidebar .table > tbody > tr > td {
    padding: 10px; }
  .contact-sidebar .table > tbody > tr > td > a {
    font-size: 14px;
    font-family: Gotham Book;
    color: #434343; }
  .contact-sidebar .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f7f7f7; }
  .contact-sidebar .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff; }

.parsley-error {
  border-color: #eb6358;
  background: url(/images/icon-error-danger.svg) right 10px center no-repeat; }

.parsley-errors-list {
  position: absolute;
  left: 15px;
  right: 15px;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

.parsley-errors-list li {
  font-size: 12px;
  color: #eb6358; }

.parsley-success {
  border-color: #68dd61;
  background: url(/images/icon-error-success.svg) right 10px center no-repeat; }

.parsley-errors-list {
  position: absolute; }

textarea.parsley-error {
  border-color: #eb6358 !important;
  background: url(/images/icon-error-danger.svg) right 10px bottom 10px no-repeat; }

textarea.parsley-success {
  border-color: #68dd61 !important;
  background: url(/images/icon-error-success.svg) right 10px bottom 10px no-repeat; }

.form-group textarea.form-control {
  height: 235px;
  resize: none; }

.mobile-contact-bar {
  display: none; }
  @media (max-width: 768px) {
    .mobile-contact-bar {
      display: block; } }

.map_heading_mask {
  background: #4EE625;
  background: -webkit-gradient(left top, right top, color-stop(0%, #4EE625), color-stop(100%, rgba(255, 255, 255, 0)));
  background: linear-gradient(to right, #4EE625 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-secondary', endColorstr='#ffffff', GradientType=1 );
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  height: 165px;
  width: 40%; }

#map_heading {
  opacity: 0.3;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 235px;
  width: 40%; }

.contact-tile {
  border: 1px solid #e6e6e6; }

.contact-details {
  padding: 5px 20px; }
  .contact-details h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px; }

.operating-hours-column {
  padding-right: 20px !important; }
  @media (max-width: 991px) {
    .operating-hours-column {
      padding-left: 10px !important;
      padding-right: 10px !important; } }

.col-contact-info {
  padding-right: 0; }
  @media (max-width: 991px) {
    .col-contact-info {
      padding-right: 15px; } }

.col-operating-hours {
  border-left: 1px solid #e6e6e6; }
  @media (max-width: 991px) {
    .col-operating-hours {
      border-left: none; } }

.hours-sub-heading {
  font-size: 14px;
  font-weight: 700;
  margin: 16px 12px; }

.contact-tile-header {
  font-size: 18px;
  font-weight: 700;
  margin: 8px 0px; }
  .contact-tile-header .icon {
    fill: #4EE625; }

.nb-td {
  border: none !important;
  padding-left: 0px !important; }

.td-head {
  font-weight: 700;
  padding-bottom: 3px !important;
  color: #25ABF0; }

.td-body {
  padding-top: 3px !important; }
  .td-body a {
    color: #0b0b0b; }

.td-hour {
  padding: 16px !important; }

.contact-form {
  margin-top: 16px; }

.contact-panel {
  margin: 10px 0px; }

.phone-container {
  width: 100%; }
  .phone-container .phone-item {
    width: 50%;
    padding: 6px 0px;
    float: left; }
    .phone-container .phone-item .heading {
      font-weight: 700;
      color: #25ABF0; }
    .phone-container .phone-item a {
      color: #0b0b0b; }

.map-container img {
  width: 100%; }

.well-blog {
  padding: 60px 0;
  background: #25ABF0 url("/images/bg-blog.png") right bottom no-repeat; }
  @media (max-width: 768px) {
    .well-blog {
      background: #25ABF0;
      padding: 30px 0; } }
  .well-blog .heading {
    font-family: Gotham Bold;
    color: #fff;
    font-size: 50px; }
    @media (max-width: 768px) {
      .well-blog .heading {
        font-size: 28px; } }
  .well-blog .copy {
    padding: 0;
    text-align: left; }

section.blog-listing, section.blog-details {
  padding: 30px 0; }

@media (max-width: 768px) {
  section.blog-listing {
    padding-bottom: 20px;
    background-color: #f5f5f5; } }

.posts-container .post-item {
  margin-bottom: 30px;
  padding: 0 0 45px 0;
  border-bottom: 1px solid #b7b7b7; }
  @media (max-width: 768px) {
    .posts-container .post-item {
      margin-bottom: 15px;
      background-color: #fff;
      border: 1px solid #e8e8e8;
      padding: 0 0 0 0; } }
  @media (max-width: 768px) {
    .posts-container .post-item .btn-primary {
      font-size: 13px; } }
  .posts-container .post-item .post-data {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
    background: transparent url("/images/post-breaker.png") left top no-repeat; }
  .posts-container .post-item .heading {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    font-size: 25px;
    color: #434343; }
    @media (max-width: 768px) {
      .posts-container .post-item .heading {
        font-size: 22px; } }
  .posts-container .post-item .copy {
    padding-left: 30px;
    float: left;
    width: 407px; }
    @media (min-width: 1200px) {
      .posts-container .post-item .copy {
        width: 557px; } }
    @media (max-width: 768px) {
      .posts-container .post-item .copy {
        padding: 30px;
        width: 100%; } }
  .posts-container .post-item .img-container {
    float: left;
    width: 290px;
    height: 235px; }
    @media (max-width: 768px) {
      .posts-container .post-item .img-container {
        width: 100%;
        height: auto; }
        .posts-container .post-item .img-container img {
          width: 100%; } }
  .posts-container .post-item .time-stamp {
    display: inline-block;
    margin-bottom: 10px;
    padding-left: 18px;
    color: #434343;
    font-size: 13px;
    text-transform: uppercase;
    background: transparent url("/images/icon-clock-sml.svg") left 40% no-repeat; }
  .posts-container .post-item .categories {
    padding-left: 21px;
    background: transparent url("/images/icon-category-sml.svg") left 40% no-repeat; }
  .posts-container .post-item .categories a {
    color: #4EE625;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 800; }
  .posts-container .post-item .excerpt {
    font-size: 15px;
    color: #6d6d6d; }
  .posts-container .post-item.quote .copy, .posts-container .post-item.download .copy, .posts-container .post-item.press .copy, .posts-container .post-item.link .copy {
    width: 100%;
    padding-left: 0; }
    @media (max-width: 768px) {
      .posts-container .post-item.quote .copy, .posts-container .post-item.download .copy, .posts-container .post-item.press .copy, .posts-container .post-item.link .copy {
        padding-left: 30px;
        padding-right: 30px; } }
  .posts-container .post-item.quote .time-stamp, .posts-container .post-item.download .time-stamp, .posts-container .post-item.press .time-stamp, .posts-container .post-item.link .time-stamp {
    display: inline-block; }
  .posts-container .post-item.quote .categories, .posts-container .post-item.download .categories, .posts-container .post-item.press .categories, .posts-container .post-item.link .categories {
    display: inline-block;
    margin-left: 20px; }
    @media (max-width: 768px) {
      .posts-container .post-item.quote .categories, .posts-container .post-item.download .categories, .posts-container .post-item.press .categories, .posts-container .post-item.link .categories {
        display: block;
        margin-left: 0; } }
  .posts-container .post-item.standard .excerpt {
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .posts-container .post-item.standard .excerpt {
        margin-bottom: 0; } }
  .posts-container .post-item.quote .quote-container {
    margin-bottom: 38px;
    width: 100%;
    position: relative;
    padding: 30px 28px;
    color: #6d6d6d;
    font-size: 18px;
    border: 1px solid #dfdfdf; }
    .posts-container .post-item.quote .quote-container .quote-copy {
      background-image: url("/images/icon-quote-left.svg"), url("/images/icon-quote-right.svg");
      background-repeat: no-repeat, no-repeat;
      background-position: top left, bottom right; }
    .posts-container .post-item.quote .quote-container:after {
      bottom: -25px;
      left: -1px;
      position: absolute;
      content: "";
      width: 23px;
      height: 25px;
      background: transparent url("/images/quote-bubble-leg.png") left top no-repeat; }
      @media (max-width: 768px) {
        .posts-container .post-item.quote .quote-container:after {
          transform: scaleY(-1);
          -ms-filter: flipv;
          /*IE*/
          filter: flipv;
          bottom: auto;
          top: -25px; } }
  .posts-container .post-item.quote .cite-container .avatar {
    margin-right: 15px;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .posts-container .post-item.quote .cite-container .name {
    color: #434343;
    font-size: 23px; }
  .posts-container .post-item.quote .cite-container .honours-title {
    font-size: 13px;
    color: #bcbcbc;
    font-style: italic; }
  @media (max-width: 768px) {
    .posts-container .post-item.quote .cite-container-desktop {
      display: none; } }
  .posts-container .post-item.quote .cite-container-mobile {
    display: none; }
    .posts-container .post-item.quote .cite-container-mobile .avatar {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      margin-bottom: 20px; }
    .posts-container .post-item.quote .cite-container-mobile .name {
      text-align: center; }
    .posts-container .post-item.quote .cite-container-mobile .honours-title {
      text-align: center;
      margin-bottom: 15px; }
    @media (max-width: 768px) {
      .posts-container .post-item.quote .cite-container-mobile {
        display: block; } }
  .posts-container .post-item.download, .posts-container .post-item.link {
    position: relative; }
    .posts-container .post-item.download .download-container, .posts-container .post-item.link .download-container {
      position: relative;
      min-height: 110px;
      padding: 30px 28px 30px 125px;
      color: #6d6d6d;
      font-size: 18px;
      border: 1px solid #dfdfdf; }
      @media (max-width: 768px) {
        .posts-container .post-item.download .download-container, .posts-container .post-item.link .download-container {
          min-height: 1px;
          padding: 35px 15px;
          margin-bottom: 40px; }
          .posts-container .post-item.download .download-container:after, .posts-container .post-item.link .download-container:after {
            position: absolute;
            bottom: -35px;
            left: 0;
            content: "";
            background: transparent url("/images/post-breaker.png") center top no-repeat;
            width: 51px;
            height: 2px; } }
      .posts-container .post-item.download .download-container:before, .posts-container .post-item.link .download-container:before {
        position: absolute;
        top: 25px;
        left: 35px;
        width: 58px;
        height: 58px;
        content: "";
        background-size: 58px; }
    .posts-container .post-item.download .post-data, .posts-container .post-item.link .post-data {
      background: none;
      margin-top: 0; }
  .posts-container .post-item.download .download-container:before {
    background: transparent url("/images/icon-download.png") left center no-repeat;
    background-size: 58px; }
    @media (max-width: 768px) {
      .posts-container .post-item.download .download-container:before {
        background: transparent; } }
  .posts-container .post-item.link .download-container:before {
    background: transparent url("/images/icon-link.png") left center no-repeat;
    background-size: 58px; }
    @media (max-width: 768px) {
      .posts-container .post-item.link .download-container:before {
        background: transparent; } }

.mobile-icon {
  display: none;
  width: 111px;
  height: 111px;
  margin: 0 auto 30px auto; }
  .mobile-icon.link-icon {
    background: transparent url("/images/icon-link.png") left center no-repeat;
    background-size: 100%; }
  .mobile-icon.download-icon {
    background: transparent url("/images/icon-download.png") left center no-repeat;
    background-size: 100%; }
  @media (max-width: 768px) {
    .mobile-icon {
      display: block; } }

.blog-details .header {
  margin-bottom: 35px;
  text-align: center; }
  .blog-details .header .heading {
    color: #434343;
    font-size: 30px; }
  .blog-details .header .blog-heading {
    margin-top: 0;
    margin-bottom: 0; }
    @media (max-width: 768px) {
      .blog-details .header .blog-heading {
        font-size: 20px; } }
  .blog-details .header .post-data {
    margin-top: 15px;
    margin-bottom: 10px;
    padding-top: 20px;
    background: transparent url("/images/post-breaker.png") center top no-repeat; }
  .blog-details .header .time-stamp {
    display: inline-block;
    margin-bottom: 10px;
    padding-left: 18px;
    color: #434343;
    font-size: 13px;
    text-transform: uppercase;
    background: transparent url("/images/icon-clock-sml.svg") left 40% no-repeat; }
  .blog-details .header .categories {
    display: inline-block;
    padding-left: 21px;
    background: transparent url("/images/icon-category-sml.svg") left 40% no-repeat; }
  .blog-details .header .categories a {
    color: #4EE625;
    font-size: 13px;
    text-transform: uppercase; }
  .blog-details .header .post-details-image {
    display: block;
    margin-top: 20px;
    max-width: 100%;
    min-width: 100%; }
  .blog-details .header .blog-details-image-container iframe {
    height: 450px; }
  @media (max-width: 768px) {
    .blog-details .header .blog-details-image-container {
      margin-left: -15px;
      margin-right: -15px; }
      .blog-details .header .blog-details-image-container iframe {
        height: 350px; } }
  @media (max-width: 480px) {
    .blog-details .header .blog-details-image-container iframe {
      height: 250px; } }

.article-pagination {
  padding-top: 20px;
  margin-top: 50px;
  border-top: 1px solid #e8e8e8; }
  @media (max-width: 768px) {
    .article-pagination {
      margin-left: -15px;
      margin-right: -15px;
      border-top: none;
      margin-top: 30px; } }
  .article-pagination .heading {
    margin-bottom: 40px;
    color: #25ABF0;
    font-size: 25px; }
    @media (max-width: 768px) {
      .article-pagination .heading {
        display: none; } }
  .article-pagination .article-heading {
    padding-top: 20px;
    padding: 20px 25px 0 25px;
    color: #25ABF0;
    font-family: Gotham Book;
    font-size: 15px;
    font-style: italic; }
    @media (max-width: 768px) {
      .article-pagination .article-heading {
        display: none; } }
  .article-pagination .mobile-copy {
    padding: 13px 10px;
    display: none;
    color: white;
    font-size: 20px; }
    @media (max-width: 768px) {
      .article-pagination .mobile-copy {
        display: block; } }
    .article-pagination .mobile-copy .fa {
      margin-top: 8px;
      font-size: 14px; }
  .article-pagination .article-copy {
    padding: 0 25px;
    color: #434343;
    font-family: Gotham Book;
    font-size: 14px; }
    @media (max-width: 768px) {
      .article-pagination .article-copy {
        display: none; } }
  .article-pagination .img-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden; }
    @media (max-width: 768px) {
      .article-pagination .img-container {
        display: none; } }
    .article-pagination .img-container:before {
      border-radius: 50%;
      transition: opacity 0.2s;
      opacity: 0;
      width: 100px;
      height: 100px;
      position: absolute;
      content: "";
      background: rgba(27, 88, 142, 0.5) url("/images/icon-chevron-left.svg") center center no-repeat; }
  .article-pagination .article-pagination-item-container {
    width: 50%;
    min-width: 50%;
    min-height: 120px;
    float: left;
    border-right: 1px solid #e8e8e8; }
    .article-pagination .article-pagination-item-container:last-child {
      border-right: none; }
    @media (max-width: 768px) {
      .article-pagination .article-pagination-item-container {
        border-right: none;
        min-height: auto; } }
  .article-pagination .article-pagination-item:hover, .article-pagination .article-pagination-item:focus, .article-pagination .article-pagination-item:active {
    text-decoration: none; }
    .article-pagination .article-pagination-item:hover .img-container:before, .article-pagination .article-pagination-item:focus .img-container:before, .article-pagination .article-pagination-item:active .img-container:before {
      transition: opacity 0.2s;
      opacity: 1; }
  @media (max-width: 768px) {
    .article-pagination .next-page {
      background-color: #25ABF0; } }
  .article-pagination .next-page .img-container {
    float: right; }
    .article-pagination .next-page .img-container:before {
      background: rgba(27, 88, 142, 0.5) url("/images/icon-chevron-right.svg") center center no-repeat; }
  .article-pagination .next-page .mobile-copy {
    text-align: left;
    padding-left: 25px; }
    .article-pagination .next-page .mobile-copy .fa {
      float: right; }
  @media (max-width: 768px) {
    .article-pagination .previous-page {
      background-color: #4EE625; } }
  .article-pagination .previous-page .img-container {
    float: left; }
  .article-pagination .previous-page .article-heading {
    padding-left: 120px; }
    @media (max-width: 768px) {
      .article-pagination .previous-page .article-heading {
        padding-left: 15px; } }
  .article-pagination .previous-page .article-copy {
    padding-left: 120px; }
    @media (max-width: 768px) {
      .article-pagination .previous-page .article-copy {
        padding-left: 15px; } }
  .article-pagination .previous-page .mobile-copy {
    text-align: right;
    padding-right: 25px; }
    .article-pagination .previous-page .mobile-copy .fa {
      float: left; }

.comment-container {
  margin-top: 45px; }
  .comment-container .heading {
    font-size: 25px;
    color: #4EE625;
    border-bottom: 1px solid #e8e8e8; }
    @media (max-width: 768px) {
      .comment-container .heading {
        font-size: 30px; } }

.download-link {
  display: block; }
  .download-link:hover, .download-link:focus, .download-link:active {
    text-decoration: none;
    background-color: #f5f5f5; }

.blog-details .quote {
  margin-bottom: 20px; }
  .blog-details .quote .quote-container {
    margin-bottom: 38px;
    width: 100%;
    position: relative;
    padding: 30px 28px;
    color: #6d6d6d;
    font-size: 18px;
    border: 1px solid #dfdfdf; }
    .blog-details .quote .quote-container .quote-copy {
      background-image: url("/images/icon-quote-left.svg"), url("/images/icon-quote-right.svg");
      background-repeat: no-repeat, no-repeat;
      background-position: top left, bottom right; }
    .blog-details .quote .quote-container:after {
      bottom: -25px;
      left: -1px;
      position: absolute;
      content: "";
      width: 23px;
      height: 25px;
      background: transparent url("/images/quote-bubble-leg.png") left top no-repeat; }
      @media (max-width: 768px) {
        .blog-details .quote .quote-container:after {
          transform: scaleY(-1);
          -ms-filter: flipv;
          /*IE*/
          filter: flipv;
          bottom: auto;
          top: -25px; } }
  .blog-details .quote .cite-container .avatar {
    margin-right: 15px;
    width: 51px;
    height: 51px;
    border-radius: 90%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .blog-details .quote .cite-container .name {
    text-align: left;
    color: #434343;
    font-size: 23px; }
  .blog-details .quote .cite-container .honours-title {
    text-align: left;
    font-size: 13px;
    color: #bcbcbc;
    font-style: italic; }
  @media (max-width: 768px) {
    .blog-details .quote .cite-container-desktop {
      display: none; } }
  .blog-details .quote .cite-container-mobile {
    display: none; }
    .blog-details .quote .cite-container-mobile .avatar {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      margin-bottom: 20px; }
    .blog-details .quote .cite-container-mobile .name {
      text-align: center; }
    .blog-details .quote .cite-container-mobile .honours-title {
      text-align: center;
      margin-bottom: 15px; }
    @media (max-width: 768px) {
      .blog-details .quote .cite-container-mobile {
        display: block; } }

.blog-details .download, .blog-details .link {
  position: relative;
  margin-bottom: 20px; }
  .blog-details .download .download-container, .blog-details .link .download-container {
    position: relative;
    min-height: 110px;
    padding: 30px 28px 30px 125px;
    color: #6d6d6d;
    font-size: 18px;
    border: 1px solid #dfdfdf; }
    .blog-details .download .download-container:before, .blog-details .link .download-container:before {
      position: absolute;
      top: 25px;
      left: 35px;
      width: 58px;
      height: 58px;
      content: "";
      background-size: 58px; }
    @media (max-width: 768px) {
      .blog-details .download .download-container, .blog-details .link .download-container {
        min-height: 1px;
        padding: 30px 28px 30px 28px; } }
  .blog-details .download .post-data, .blog-details .link .post-data {
    background: none;
    margin-top: 0; }

.blog-details .download .download-container:before {
  background: transparent url("/images/icon-download.png") left center no-repeat;
  background-size: 58px; }
  @media (max-width: 768px) {
    .blog-details .download .download-container:before {
      background: transparent; } }

.blog-details .link .download-container:before {
  background: transparent url("/images/icon-link.png") left center no-repeat;
  background-size: 58px; }
  @media (max-width: 768px) {
    .blog-details .link .download-container:before {
      background: transparent; } }

.related-post-img-container {
  background-repeat: no-repeat;
  background-position: center;
  packground-size: cover; }

.pagination-disabled {
  display: none; }
  @media (max-width: 768px) {
    .pagination-disabled {
      display: block;
      pointer-events: none;
      background-color: #C1C1C1 !important; } }

/*# sourceMappingURL=screen.css.map */
