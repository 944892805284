#map {
    height: 215px;
}

section.contact {
    padding: 55px 0 85px 0;
    .btn-quad {
        margin-top: 8px;
    }
    .label {
        position: relative;
        top: -1px;
        padding: 5px 4px 5px 4px;
    }
}

.well-contact {
    position: relative;
    overflow:hidden;
    // background: $color-secondary url("/images/gb-contact.png") right top no-repeat;
    background-color: $color-secondary;
    @include breakpoint(tablet) {
        background: $color-secondary;
    }

    .heading {
        font-family: $font-secondary;
        color: #fff;
        font-size: 50px;
        @include breakpoint(tablet) {
            font-size: 28px;
        }
    }
    .copy {
        padding: 0;
        text-align: left;
    }
}

.form-control {
    height: 43px;
    border: 1px solid #d9d9d9;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    color: #535353;
    font-family: $font-tert;
    font-size: 14px;
}

.contact-sidebar {
    border: 1px solid #e6e6e6;
    @include breakpoint(tablet-large) {
        margin-top: 30px;
    }

    @include breakpoint(tablet) {
        display: none;
    }

    .copy-container {
        padding: 50px 30px 30px 30px;
    }
    .heading {
        margin-bottom: 20px;
        text-align: center;
        font-size: 22px;
        font-family: $font-secondary;
        color: $color-primary;
    }
    .sub-heading {
        margin-bottom: 20px;
        text-align: center;
        font-size: 22px;
        font-family: $font-secondary;
        font-family: $medium-grey;
    }
    .address {
        position: relative;
        padding-bottom: 30px;
        margin: 0 auto;
        width: 100%;
        padding-left: 37px;
        font-size: 16px;
        color: $medium-grey;
        font-family: $font-tert;
        // background: transparent url("/images/icon-map-pin.svg") left top no-repeat;
        .icon{
            svg{
                fill:$color-secondary;
            }
            position: absolute;
            left: 0;
            top: 0;
        }
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        @include breakpoint(desktop-large) {
            width: 268px;
        }
    }
    .table {
        @include breakpoint(tablet-large) {
            width: 450px;
            margin: 0 auto;
        }
    }
    .table-striped tr td {
        padding-left: 30px;
        border: none;
        font-size: 14px;
        font-family: $font-tert;
        color: $medium-grey;
        &:first-child {
            padding-left: 25px;
            color: $color-primary;
            font-family: $font-secondary;
        }
    }
    .table>tbody>tr>td {
        padding: 10px;
    }
    .table>tbody>tr>td>a {
        font-size: 14px;
        font-family: Gotham Book;
        color: $medium-grey;
    }
    .table-striped > tbody > tr:nth-of-type(even) {
        background-color: #f7f7f7;
    }
    .table-striped > tbody > tr:nth-of-type(odd) {
        background-color: #fff;
    }
}

.parsley-error {
    border-color: $color-offline;
    background: url(/images/icon-error-danger.svg) right 10px center no-repeat;
}

.parsley-errors-list {
    position: absolute;
    left: 15px;
    right: 15px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}

.parsley-errors-list li {
    font-size: 12px;
    color: $color-offline;
}

.parsley-success {
    border-color: $color-online;
    background: url(/images/icon-error-success.svg) right 10px center no-repeat;
}

.parsley-errors-list {
    position: absolute;
}

textarea.parsley-error {
    border-color: $color-offline !important;
    background: url(/images/icon-error-danger.svg) right 10px bottom 10px no-repeat;
}

textarea.parsley-success {
    border-color: $color-online !important;
    background: url(/images/icon-error-success.svg) right 10px bottom 10px no-repeat;
}

.form-group textarea.form-control {
    height: 235px;
    resize: none;
}

.mobile-contact-bar {
    display: none;
    @include breakpoint(tablet) {
        display: block;
    }
}


.map_heading_mask{

    background: $color-secondary;
    background: -moz-linear-gradient(left, $color-secondary 0%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, $color-secondary), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(left, $color-secondary 0%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(left, $color-secondary 0%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(left, $color-secondary 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, $color-secondary 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-secondary', endColorstr='#ffffff', GradientType=1 );
    position: absolute;
    z-index:2;
    right: 0;
    top: 0;
    height: 165px;
    width: 40%;    
}
#map_heading{
    opacity:0.3;
    position: absolute;
    z-index:1;
    right: 0;
    top: 0;
    height: 235px;
    width: 40%;    
}

.contact-tile {
    border: 1px solid #e6e6e6;
}

.contact-info-column {
    // border-right: 1px solid #e6e6e6;
}

.contact-details {
    padding: 5px 20px;
    h5 {
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 20px;
    }
}

.operating-hours-column {
    padding-right: 20px !important;
    @include breakpoint(tablet-large) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
.col-contact-info{
    padding-right: 0;
    @include breakpoint(tablet-large) {
        padding-right: 15px;
    }
}
.col-operating-hours{
    border-left: 1px solid #e6e6e6;
    @include breakpoint(tablet-large) {
        border-left: none;
    }
}

.hours-sub-heading {
    font-size: 14px;
    font-weight: $font-weight-bold;
    margin: 16px 12px;
}

.contact-tile-header {
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin: 8px 0px;
    .icon{
        fill:$color-secondary;
    }
}

.nb-td {
    border: none !important;
    padding-left: 0px !important;
}

.td-head {
    font-weight: $font-weight-bold;
    padding-bottom: 3px !important;
    color: $color-primary;
}

.td-body {
    padding-top: 3px !important;

    a {
        color: $black;
    }
}


.td-hour {
    padding: 16px !important;
}

.contact-form {
    margin-top: 16px;
}

.contact-panel {
    margin: 10px 0px;
}

.phone-container{
    width: 100%;
    .phone-item{
        width: 50%;
        padding: 6px 0px;
        float: left;
        .heading{
            font-weight: $font-weight-bold;
            color: $color-primary;
        }
        a {
            color: $black;
        }
    }
}

.map-container{
    img{
        width: 100%;
    }
}