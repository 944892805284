// ==========================================================================
// Global Config
// ==========================================================================

// Font Awesome Icons
//--------------------------------------------------------------------------------//
// $include-font-awesome: true; // Will import font awesome icon font if set to true. Set to 'false' to not include. http://fortawesome.github.io/Font-Awesome/icons/

// // Fonts - Google 
// //--------------------------------------------------------------------------------//
// $font-url-google           : 'https://fonts.googleapis.com/css?family=Varela+Round';
// $font-secondary-url-google : 'http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900';

// Fonts - Custom font files (Place files in 'fonts' folder at the same level as your css folder)
//--------------------------------------------------------------------------------//

// Primary
// $font-custom-name: Gotham Rounded Light;
// $font-custom-file-name: GothamRnd-Light;

// Secondary
$font-secondary-custom-name: Gotham Bold;
$font-secondary-custom-file-name: gotham-bold;

// Tert
$font-tert-custom-name: Gotham Book;
$font-tert-custom-file-name: gotham-book;

// Font Stacks
//--------------------------------------------------------------------------------//
$font-primary: "Varela Round", sans-serif;
$font-secondary: $font-secondary-custom-name;
$font-tert: $font-tert-custom-name;

// Font Weights
//--------------------------------------------------------------------------------//
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-bold: 700;
$font-weight-ultra-bold: 900;

// Descriptive Base Colors
//--------------------------------------------------------------------------------//
$white: white;
$off-white: #f5f5f5;
$black: #0b0b0b;
$tundora: #434343;

$light-grey: #e0e0e0;
$grey: #d7d7d7;
$dark-grey: #888888;
$medium-grey: $tundora;
$mercury: #e9e9e9;
$wild_sand: whitesmoke;
$dove-grey: #6e6e6e;

$color-online:#68dd61;
$color-offline:#eb6358;


// Colour name picker: http://chir.ag/projects/name-that-color/#6195ED



$orange: #ef3d1f; //orange
$blue: #285cb2; //blue
$blue-light: lighten(#0d75bc, 10%); //blue-light
$yellow: #fbda19; //yellow


$test-dark-grey: #eeeeee;
$test-grey: #888888;
$test-light-grey: #333333;

// Color Usage
//--------------------------------------------------------------------------------//
// $color-primary: $blue;
// $color-secondary: $orange;
// $color-tert: $yellow;

// $color-primary: $test-light-grey;
// $color-secondary: $test-grey;
// $color-tert: $test-dark-grey;
// $color-accent: $blue-light;

$text-primary: $tundora;
$text-secondary: $dove-grey;

$color-shadow: rgba($black, 0.2);

// Text
//--------------------------------------------------------------------------------//
$base-font-size: 16px;
$base-line: 24px;

// Path to Assets 
//--------------------------------------------------------------------------------//
$path-rel: "../img/";