.wysiwyg {
    color: #6d6d6d;
    font-family: $font-tert;
    p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 15px;
        @include breakpoint(tablet) {
            font-size: 15px;
        }
    }
    h2 {
        margin: 40px 0 15px 0;
        font-size: 30px;
        color: $color-primary;
        @include breakpoint(tablet) {
            font-size: 21px;
            margin: 35px 0;
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
    h3 {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 25px;
        color: $color-secondary;
        @include breakpoint(tablet) {
            font-size: 18px;
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
    ul {
        position: relative;
        padding-left: 0px;
        margin-bottom: 30px;
        font-size: 16px;
        color: #6d6d6d;
        font-family: $font-tert;
        list-style: none;
        @include breakpoint(tablet) {
            font-size: 15px;
        }

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 10px;
            @include breakpoint(tablet) {
                padding-left: 15px;
            }
        }
        li:before {
            position: absolute;
            left: 0px;
            // top:-9px;
            line-height: 22px;
            font-size: 30px;
            content: "\2022";
            color: $color-secondary;
            @include breakpoint(tablet) {
                left: -1px;
            }
        }
    }
    ol {
        // display: initial;
        position: relative;
        padding-left: 0px;
        margin-bottom: 30px;
        font-size: 16px;
        color: #6d6d6d;
        font-family: $font-tert;
        list-style: none;
        counter-reset: ol-counter;
        @include breakpoint(tablet) {
            font-size: 15px;
        }

        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 10px;
            @include breakpoint(tablet) {
                padding-left: 15px;
            }
        }
        li:before {
            position: absolute;
            left: 0px;
            top: 2px;
            font-size: 16px;
            content: counter(ol-counter);
            counter-increment: ol-counter;
            color: $color-secondary;
        }
    }
    .alignleft, img[style*="float:left;"], img[style*="float: left;"] {
        float: left;
        margin: 0px 30px 30px 0px;
    }
    .alignright, img[style*="float:right;"], img[style*="float: right;"] {
        float: right;
        margin: 0px 0px 30px 30px;
    }
    .aligncenter, img.aligncenter {
        margin: 30px;
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}