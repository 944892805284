.navbar-comm {
    margin-bottom: 0;
    min-height: 33px;
    background-color: $mercury;
    @include border-radius(0);
    @include breakpoint(tablet) {
        min-height: 20px;
        border: none;
    }

    .nav {
        @include breakpoint(tablet) {
            text-align: center;
            margin: 2.5px -15px;
        }

        li {
            a {
                position: relative;
                top: -1px;
                margin-bottom: -2px;
                height: 33px;
                padding: 7px 16px 4px 16px;
                border-left: 1px solid $grey;
                color: $dark-grey;
                @include breakpoint(tablet) {
                    text-align: right;
                    display: inline;
                    border: none !important;
                }
            }
            @include breakpoint(tablet) {
                display: inline;
                &:first-child {
                    a {
                        float: left;
                        text-align: left;
                    }
                }
            }

            &:last-child a {
                border-right: 1px solid $grey;
                @include breakpoint(tablet) {
                    float: right;
                }
            }
        }
    }
    .dropdown-menu {
        @include breakpoint(tablet) {
            li {
                width: 100%;
                a {
                    text-align: right !important;
                    width: 100%;
                }
                &:first-child a {
                    border-top: 1px solid $grey !important;
                }
            }
        }
    }
    .nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
        border-color: $grey;
        color: darken($dark-grey, 10%);
        background-color: #fff;
        @include breakpoint(tablet) {
            background-color: transparent;
            border-bottom: 1px solid $grey;
        }
    }
}

.navbar-main {
    padding: 14px 0px 15px 0px;
    margin-bottom: 0;
    @include border-radius(0);
    .nav {
        padding-top: 2px;
        li {
            a {
                padding: 8px 13px 8px 13px;
                border-left: 1px solid $grey;
                color: $dark-grey;
                text-transform: uppercase;
                &:hover {
                    background-color: #fff;
                    color: darken($dark-grey, 20%);
                }
            }
            &:first-child a {
                border-left: none;
            }
            &:last-child a {
                border-right: 1px solid $grey;
            }
        }
    }
    .dropdown-menu {
        min-width: 100%;
        li {
            a {
                border-left: none;
            }
            a:hover {
                background-color: $mercury;
            }
        }
    }
    .nav .open>a {
        border-color: $grey;
    }
    .fa-chevron-down {
        position: relative;
        margin-left: 11px;
        top: -2px;
        font-size: 10px;
    }
    .navbar-brand {
        padding: 4px 0;
        position: absolute;
        top: 0px;
        bottom: 0px;
        height: 100%;
        min-width: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
        img {
            height: 100%;
        }
    }
    .nav .open>a, .nav .open>a:focus, .nav .open {
        background-color: #fff;
        color: darken($dark-grey, 20%);
    }
}

.nav-border {
    width: 100%;
    height: 3px;
    background: $color-primary;
    /* Old browsers */
    background: -moz-linear-gradient(left, $color-primary 0%, lighten($color-accent, 15%) 50%,  $color-secondary 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $color-primary 0%, lighten($color-accent, 15%) 50%,  $color-secondary 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-primary 0%, lighten($color-accent, 15%) 50%,  $color-secondary 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-primary', endColorstr='$color-secondary',GradientType=1 );
    /* IE6-9 */

}

.mobile-nav-border {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#96d402+0,729fe2+50,612592+100 */
    background: $color-secondary;
    /* Old browsers */
    background: -moz-linear-gradient(left, $color-secondary 0%, lighten($color-accent, 15%) 50%,  $color-primary 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, $color-secondary 0%, lighten($color-accent, 15%) 50%,  $color-primary 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $color-secondary 0%, lighten($color-accent, 15%) 50%,  $color-primary 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$color-secondary', endColorstr='$color-primary',GradientType=1 );
    /* IE6-9 */
}

.main-banner {
    width: 100%;
    height: 575px;
    // background-size: cover;
    @include breakpoint(tablet-large) {
        height: 573px;
    }

    @include breakpoint(tablet) {
        height: auto;
        background-size: cover;
        position: relative;
    }

    .carousel-indicators {
        bottom: 40px;
        @include breakpoint(tablet-large) {
            bottom: 0px;
        }

        @include breakpoint(tablet) {
            bottom: auto;
            top: 350px;
        }
        @include breakpoint(phone) {
            top: 215px;
        }

        li {
            border-color: $color-primary;
            margin: 0 1px;
            height: 12px;
            width: 12px;
        }
        .active {
            height: 12px;
            width: 12px;
            border-color: $color-primary;
            background-color: $color-primary;
        }
    }
    .carousel-inner{
        @include breakpoint(tablet) {
            overflow: visible;
        }
    }
    .heading {
        font-size: 40px;
        color: $medium-grey;
        font-weight: 800;
        @include breakpoint(tablet) {
            text-align: center;
        }
        @include breakpoint(phone) {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }
    .sub-heading {
        margin-bottom: 25px;
        color: $medium-grey;
        font-size: 16px;
        @include breakpoint(tablet) {
            text-align: center;
        }
        @include breakpoint(phone) {
            display: none;
        }
    }
    .light-indicator {
        border: 1px solid $medium-grey !important;
    }
    .item {
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: 575px;
        @include breakpoint(tablet) {
            background-position: top center;
            background-size: auto 376px;
            height: auto;
        }

        @include breakpoint(phone) {
            background-size: auto 240px;
        }

        &.dark {
            .heading {
                color: #ffffff;
            }
            .sub-heading {
                color: #ffffff;
            }
        }
        @include breakpoint(tablet) {
            &.dark {
                .heading {
                    color: $medium-grey;
                }
                .sub-heading {
                    color: $medium-grey;
                }
            }
        }
    }
}

.carousel-image {
    opacity: 0;
}

.banner-copy {
    margin-top: 180px;
    @include breakpoint(tablet) {
        margin-top: 375px;
        padding: 5px 0 30px 0;
        .btn-primary{
            display: block;
            width: 240px;
            margin: 0 auto;
        }
    }
    @include breakpoint(phone) {
        margin-top: 240px;
    }
}

.status-dot {
    font-size: 12px;
    &.online {
        color: $color-online;
    }
    &.offline {
        color: $color-offline;
    }
    &.alt {
        position: relative;
        right: -7px;
        top: -1px;
    }
}

.nav-container {
    z-index: 30;
    left: 0;
    right: 0;
    background-color: #fff;
    position: fixed;
    @include breakpoint(tablet-large) {
        display: none;
    }

    // @include breakpoint(tablet) {
    // 	border:1px solid blue;
    // }
    // @include breakpoint(phone) {
    // 	border:1px solid pink;
    // }
}

section.page {
    padding-top: 102px;
    @include breakpoint(tablet-large) {
        padding-top: 0;
    }
}

.container-animation-fix {
    padding-right: 0;
}

.nav-mobile {
    display: none;
    background: #fff;
    @include breakpoint(tablet-large) {
        .container {
            margin-bottom: 1px;
        }
    }

    @include breakpoint(tablet-large) {
        display: block;
    }

    .navbar-brand {
        margin-left: -15px;
        height: 61px;
        padding: 10px 15px;
        img {
            height: 100%;
        }
    }
    .mobile-menu {
        float: right;
        font-size: 35px;
        color: $color-primary;
        cursor: pointer;
    }
}

#sb-site {
    @include box-shadow(1px, 0px, 5px, 0px, rgba(50, 50, 50, 0.3));
    @include breakpoint(tablet-large) {
        padding-top: 98px !important;
    }

    @include breakpoint(tablet) {
        padding-top: 99px !important;
    }
}

.sb-slidebar {
    background-color: #ffffff !important;
}

.mobile-main-navigation-logo {
    padding: 12px 15px;
    min-height: 51px;
    img {
        width: 166px;
    }
}

.mobile-main-navigation {
    list-style-type: none;
    padding-left: 0;
    .fa-chevron-down {
        float: right;
        font-size: 15px;
        margin-top: 8px;
    }
    li {
        a {
            max-height: 59px;
            font-size: 20px;
            padding: 15px 20px;
            display: block;
            color: $dark-grey;
            border-bottom: 1px solid $light-grey;
            font-size: 20px;
            color: $color-primary;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .dropdown-backdrop {
        position: static;
    }
    .dropdown-menu {
        margin-top: 0;
        float: none;
        // margin-bottom: 20px;
        z-index: 8000;
        // display: block;
        position: initial;
        width: 100%;
        padding: 0;
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-color: $off-white;
        li {
            a {
                font-size: 18px;
                padding: 15px 20px;
                padding-left: 30px;
                border-color: $grey;
                color: $medium-grey;
            }
            &:last-child a {
                border-color: #f0dcf9;
            }
        }
    }
    .open {
        .fa-chevron-down {
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=6);
        }
    }
}

.mobile-navigation-container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 98px;
    color: white;
    background: #fff;
    line-height: 6rem;
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
    transition: .25s transform;
    backface-visibility: hidden;
    z-index: 3000;
    @include breakpoint(tablet-large) {
        display: block;
    }

    &.heads-up {
        transform: translateY(-99px);
        transform: translate3d(0, -99px, 0);
    }
    .navbar-comm {
        @include breakpoint(tablet) {
            .nav {
                height: 30px;
            }
            li {
                top: -14px;
                a {
                    border-right: 1px solid $grey !important;
                    padding: 9px 16px 7px 16px;
                }
            }
            li:first-child a {
                float: none;
            }
            li:last-child a {
                float: none;
                border-right: none;
            }
        }
    }
}