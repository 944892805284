// ==========================================================================
// Helpers
// ==========================================================================

.db {display: block;}
.opac0 {opacity: 0;}
.dib {display: inline-block;}
.rl {position: relative;}
.p100 {width: 100%;}

.mt5 {margin-top: 5px;}
.mt10 {margin-top: 10px;}
.mt15 {margin-top: 15px;}
.mt20 {margin-top: 20px;}
.mb5 {margin-bottom: 5px;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px;}
.mb20 {margin-bottom: 20px;}
.mb100 {margin-bottom: 100px;}
.mb150 {margin-bottom: 150px;}

.pt5 {padding-top: 5px;}
.pt10 {padding-top: 10px;}
.pt15 {padding-top: 15px;}
.pt20 {padding-top: 20px;}
.pb5 {padding-bottom: 5px;}
.pb10 {padding-bottom: 10px;}
.pb15 {padding-bottom: 15px;}
.pb20 {padding-bottom: 20px;}
.pl17 {padding-left: 17px;}	
.tablet-pb0{
	@include breakpoint(tablet) {
		padding-bottom: 0 !important;
	}
}


.phone-only{
	display: none !important;
	@include breakpoint(phone) {
		display: block !important;
	}
}

.phone-hidden{
	@include breakpoint(phone) {
		display: none !important;
	}
}