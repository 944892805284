/*@import url("https://fonts.googleapis.com/css?family=Varela+Round");*/
/*@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");*/

@font-face {
	font-family: gotham-book;
	src: url('/fonts/gotham-book.eot');
	src: url('/fonts/gotham-book.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gotham-book.woff') format('woff'),
		url('/fonts/gotham-book.ttf')  format('truetype'),
		url('/fonts/gotham-book.svg#b3bd5af04c0e7cf2188fad8c1f2958f5') format('svg');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: gotham-bold;
	src: url('/fonts/gotham-bold.eot');
	src: url('/fonts/gotham-bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/gotham-bold.woff') format('woff'),
		url('/fonts/gotham-bold.ttf')  format('truetype'),
		url('/fonts/gotham-bold.svg#b3bd5af04c0e7cf2188fad8c1f2958f5') format('svg');
	font-style: normal;
	font-weight: normal;
}