// ==========================================================================
// Utilities
// ==========================================================================


// Import if Google Fonts URL is defined
//--------------------------------------------------------------------------------//
// @if variable-exists(font-url-google) {
// 	@import url($font-url-google);
// }

// @if variable-exists(font-secondary-url-google ) {
// 	@import url($font-secondary-url-google );
// }

// // Import Font Awesome icons if set to true
// //--------------------------------------------------------------------------------//
// @if $include-font-awesome {
// 	@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');
// }

// Import Custom Font Files if defined in 'config.scss' file
//--------------------------------------------------------------------------------//
@if variable-exists(font-custom-name) {
	@if variable-exists(font-custom-file-name) {
		@font-face {
		font-family: $font-custom-name;
		src: url('/fonts/#{$font-custom-file-name}.eot');
		src: url('/fonts/#{$font-custom-file-name}.eot?#iefix') format('embedded-opentype'),
			url('/fonts/#{$font-custom-file-name}.woff') format('woff'),
			url('/fonts/#{$font-custom-file-name}.ttf')  format('truetype'),
			url('/fonts/#{$font-custom-file-name}.svg#b3bd5af04c0e7cf2188fad8c1f2958f5') format('svg');
		font-style:   normal;
		font-weight:  200;
		}
	}
}

@if variable-exists(font-secondary-custom-name) {
	@if variable-exists(font-secondary-custom-file-name) {
		@font-face {
		font-family: $font-secondary-custom-name;
		src: url('/fonts/#{$font-secondary-custom-file-name}.eot');
		src: url('/fonts/#{$font-secondary-custom-file-name}.eot?#iefix') format('embedded-opentype'),
			url('/fonts/#{$font-secondary-custom-file-name}.woff') format('woff'),
			url('/fonts/#{$font-secondary-custom-file-name}.ttf')  format('truetype'),
			url('/fonts/#{$font-secondary-custom-file-name}.svg#b3bd5af04c0e7cf2188fad8c1f2958f5') format('svg');
		font-style:   normal;
		font-weight:  200;
		}
	}
}

@if variable-exists(font-tert-custom-name) {
	@if variable-exists(font-tert-custom-file-name) {
		@font-face {
		font-family: $font-tert-custom-name;
		src: url('/fonts/#{$font-tert-custom-file-name}.eot');
		src: url('/fonts/#{$font-tert-custom-file-name}.eot?#iefix') format('embedded-opentype'),
			url('/fonts/#{$font-tert-custom-file-name}.woff') format('woff'),
			url('/fonts/#{$font-tert-custom-file-name}.ttf')  format('truetype'),
			url('/fonts/#{$font-tert-custom-file-name}.svg#b3bd5af04c0e7cf2188fad8c1f2958f5') format('svg');
		font-style:   normal;
		font-weight:  200;
		}
	}
}

// Assets
//--------------------------------------------------------------------------------//
@function asset($file-name) {
	@return '#{$path-rel}#{$file-name}';
}

// Calculate em values
//--------------------------------------------------------------------------------//
@function em($target, $context: $base-font-size) {
	@return ($target / $context) * 1em;
}