.well-blog{
	padding: 60px 0;
	background:$color-primary url('/images/bg-blog.png') right bottom no-repeat;
	@include breakpoint(tablet) {
		background:$color-primary;
		padding: 30px 0;
	}
	.heading{
		font-family: $font-secondary;
		color: #fff;
		font-size: 50px; 
		@include breakpoint(tablet) {
			font-size: 28px;
		}
	}
	.copy{
		padding: 0;
		text-align: left;
	}
}

section.blog-listing, section.blog-details{
	padding: 30px 0;
	@include breakpoint(tablet) {
	}	
}
section.blog-listing{
	@include breakpoint(tablet) {
		padding-bottom: 20px;
		background-color: #f5f5f5;
	}
}


.posts-container{
	.post-item{
		margin-bottom: 30px;
		padding: 0 0 45px 0;
		border-bottom:1px solid #b7b7b7;
		@include breakpoint(tablet) {
			margin-bottom: 15px;
			background-color: #fff;
			border:1px solid #e8e8e8;
			padding: 0 0 0 0;
		}
		.btn-primary{
			@include breakpoint(tablet) {
				font-size: 13px;
			}
		}
		.post-data{
			margin-top: 20px;
			margin-bottom: 10px;
			padding-top: 20px;
			background:transparent url('/images/post-breaker.png') left top no-repeat;
		}
		.heading{
			margin-top: 0;
			margin-bottom: 0;
			display: inline-block;
			font-size: 25px;
			color: $medium-grey;
			@include breakpoint(tablet) {
				font-size: 22px;
			}
		}
		.copy{
			padding-left: 30px;
			float:left;
			width: 407px;
			@include breakpoint(desktop-large) {
				width: 557px;
			}
			@include breakpoint(tablet) {
				padding: 30px;
				width: 100%;
			}
		}
		.img-container{
			float:left;
			width: 290px;
			height: 235px;
			@include breakpoint(tablet) {
				width: 100%;
				height: auto;
				img{
					width: 100%;
				}
			}
		}
		.time-stamp{
			display: inline-block;
			margin-bottom: 10px;
			padding-left: 18px;
			color: $medium-grey;
			font-size: 13px;
			text-transform: uppercase;
			background:transparent url('/images/icon-clock-sml.svg') left 40% no-repeat;
		}
		.categories{
			padding-left: 21px;
			background:transparent url('/images/icon-category-sml.svg') left 40% no-repeat;
		}
		.categories a{
			color: $color-secondary;
			font-size: 13px;
			text-transform: uppercase;
			font-weight: 800;
		}
		.excerpt{
			font-size: 15px;
			color: #6d6d6d;
		}
		&.quote, &.download, &.press, &.link{
			.copy{
				width: 100%;
				padding-left: 0;
				@include breakpoint(tablet) {
					padding-left: 30px;
					padding-right: 30px;
				}
			}
			.time-stamp{
				display: inline-block;
			}
			.categories{
				display: inline-block;
				margin-left: 20px;
				@include breakpoint(tablet) {
					display: block;
					margin-left: 0;
				}
			}
		}
		&.standard{
			.excerpt{
				margin-bottom: 20px;
				@include breakpoint(tablet) {
					margin-bottom: 0;
				}
			}
		}
		&.quote{
			.quote-container{
				margin-bottom: 38px;
				width: 100%;
				position: relative;
				padding:30px 28px;
				color: #6d6d6d;
				font-size: 18px;
				border:1px solid #dfdfdf;
				.quote-copy{
					background-image:url('/images/icon-quote-left.svg'), url('/images/icon-quote-right.svg');
					background-repeat: no-repeat, no-repeat;
					background-position: top left, bottom right;
				}
				&:after{
					bottom: -25px;
					left: -1px;
					position: absolute;
					content:"";
					width: 23px;
					height: 25px;
					background:transparent url('/images/quote-bubble-leg.png') left top no-repeat;
					@include breakpoint(tablet) {
						-moz-transform: scaleY(-1);
						-webkit-transform: scaleY(-1);
						-o-transform: scaleY(-1);
						transform: scaleY(-1);
						-ms-filter: flipv; /*IE*/
						filter: flipv;
						bottom:auto;
						top:-25px;
					}
				}
			}
			.cite-container{
				.avatar{
					margin-right: 15px;
					width: 51px;
					height: 51px;
					@include border-radius(50%);
					overflow:hidden;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				.name{
					color: $medium-grey;
					font-size: 23px;
				}
				.honours-title{
					font-size: 13px;
					color: #bcbcbc;
					font-style: italic;
				}
			}
			.cite-container-desktop{
				@include breakpoint(tablet) {
					display: none;
				}
			}
			.cite-container-mobile{
				display: none;
				.avatar{
					width: 110px;
					height: 110px;
					margin: 0 auto;
					margin-bottom: 20px;
				}
				.name{
					text-align: center;
				}
				.honours-title{
					text-align: center;
					margin-bottom: 15px;
				}
				@include breakpoint(tablet) {
					display: block;
				}
			}
		}
		&.download, &.link{
			position: relative;
			.download-container{
				position: relative;
				min-height: 110px;
				padding:30px 28px 30px 125px;
				color: #6d6d6d;
				font-size: 18px;
				border:1px solid #dfdfdf;
				@include breakpoint(tablet) {
					min-height: 1px;
					padding: 35px 15px;
					margin-bottom: 40px;
					&:after{
						position: absolute;
						bottom:-35px;
						left: 0;
						content:"";
						background:transparent url('/images/post-breaker.png') center top no-repeat;
						width: 51px;
						height: 2px;
					}
				}
				&:before{
					position: absolute;
					top: 25px;
					left: 35px;
					width: 58px;
					height: 58px;
					content:"";
					background-size: 58px;
				}
			}
			.post-data{
				background:none;
				margin-top: 0;
			}
		}
		&.download{
			.download-container{
				&:before{
					background:transparent url('/images/icon-download.png') left center no-repeat;
					background-size: 58px;
					@include breakpoint(tablet) {
						background: transparent;
					}
				}
			}
			
		}
		&.link{
			.download-container{
				&:before{
					background:transparent url('/images/icon-link.png') left center no-repeat;
					background-size: 58px;
					@include breakpoint(tablet) {
						background: transparent;
					}
				}
			}
			
		}
	}
}

.mobile-icon{
	display: none;
	width: 111px;
	height: 111px;
	margin: 0 auto 30px auto;
	&.link-icon{
		background:transparent url('/images/icon-link.png') left center no-repeat;
		background-size: 100%;
	}
	&.download-icon{
		background:transparent url('/images/icon-download.png') left center no-repeat;
		background-size: 100%;
	}
	@include breakpoint(tablet) {
		display: block;
	}
}

.blog-details{
	.header{
		margin-bottom: 35px;
		text-align: center;
		.heading{
			color: $medium-grey;
			font-size: 30px;
		}
		.blog-heading {
			margin-top: 0;
			margin-bottom: 0;
			@include breakpoint(tablet) {
				font-size: 20px;
			}
		}
		.post-data{
			margin-top: 15px;
			margin-bottom: 10px;
			padding-top: 20px;
			background:transparent url('/images/post-breaker.png') center top no-repeat;
		}
		.time-stamp{
			display: inline-block;
			margin-bottom: 10px;
			padding-left: 18px;
			color: $medium-grey;
			font-size: 13px;
			text-transform: uppercase;
			background:transparent url('/images/icon-clock-sml.svg') left 40% no-repeat;
		}
		.categories{
			display: inline-block;
			padding-left: 21px;
			background:transparent url('/images/icon-category-sml.svg') left 40% no-repeat;
		}
		.categories a{
			color: $color-secondary;
			font-size: 13px;
			text-transform: uppercase;
		}
		.post-details-image{
			display: block;
			margin-top: 20px;
			max-width: 100%;
			min-width: 100%;
		}
		.blog-details-image-container{
			iframe{
				height:450px;
			}
			@include breakpoint(tablet) {
				margin-left: -15px;
				margin-right: -15px;
				iframe{
					height:350px;
				}
			}
			@include breakpoint(phone) {
				iframe{
					height:250px;
				}
			}
		}
	}
}
.article-pagination{
	padding-top: 20px;
	margin-top: 50px;
	border-top:1px solid #e8e8e8;
	@include breakpoint(tablet) {
		margin-left: -15px;
		margin-right: -15px;
		border-top: none;
		margin-top: 30px;
	}
	.heading{
		margin-bottom: 40px;
		color: $color-primary;
		font-size: 25px;
		@include breakpoint(tablet) {
			display: none;

		}
	}
	.article-heading{
		padding-top: 20px;
		padding: 20px 25px 0 25px;
		color: $color-primary;
		font-family: $font-tert;
		font-size: 15px;
		font-style: italic;
		@include breakpoint(tablet) {
			display: none;
		}
	}
	.mobile-copy{
		padding: 13px 10px;

		display: none;
		color:$white;
		font-size: 20px;
		@include breakpoint(tablet) {
			display: block;
		}
		.fa{
			margin-top: 8px;
			font-size: 14px;
		}
	}
	.article-copy{
		padding: 0 25px;
		color: $medium-grey;
		font-family: $font-tert;
		font-size: 14px;
		@include breakpoint(tablet) {
			display: none;
		}
	}
	.img-container{
		@include breakpoint(tablet) {
			display: none;
		}
		position: relative;
		width: 100px;
		height: 100px;
		@include border-radius(50%);
		overflow:hidden;
		&:before{
			@include border-radius(50%);
			transition:opacity 0.2s;
			opacity: 0;
			width: 100px;
			height: 100px;
			position: absolute;
			content:"";
			background:rgba(27, 88, 142, 0.5) url('/images/icon-chevron-left.svg') center center no-repeat;
		}
	}
	.article-pagination-item-container{
		width: 50%;
		min-width: 50%;
		min-height: 120px;
		float:left;
		border-right:1px solid #e8e8e8;
		&:last-child{
			border-right:none;
		}
		@include breakpoint(tablet) {
			border-right:none;
			min-height: auto;
		}
	}
	.article-pagination-item{
		&:hover,
		&:focus,
		&:active,
		{
			text-decoration: none;
			.img-container:before{
				transition:opacity 0.2s;
				opacity: 1;
			}
		}
	}
	.next-page{
		@include breakpoint(tablet) {
			background-color: $color-primary;
		}
		.img-container{
			float:right;
			&:before{
				background:rgba(27, 88, 142, 0.5) url('/images/icon-chevron-right.svg') center center no-repeat;
			}
		}
		.mobile-copy{
			text-align: left;
			padding-left: 25px;
			.fa{
				float: right;
			}
		}
	}
	.previous-page{
		@include breakpoint(tablet) {
			background-color: $color-secondary;
		}
		.img-container{
			float:left;
		}
		.article-heading{
			padding-left: 120px;
			@include breakpoint(tablet) {
				padding-left: 15px;
			}
		}
		.article-copy{
			padding-left: 120px;
			@include breakpoint(tablet) {
				padding-left: 15px;
			}
		}
		.mobile-copy{
			text-align: right;
			padding-right: 25px;
			.fa{
				float:left;
			}
		}
	}
}
.comment-container{
	margin-top: 45px;
	.heading{
		font-size: 25px;
		color: $color-secondary;
		border-bottom: 1px solid #e8e8e8;
		@include breakpoint(tablet) {
			font-size: 30px;
		}
	}
}

.download-link{
	display: block;
	&:hover,
	&:focus,
	&:active{
		text-decoration: none;
		background-color: #f5f5f5;
	}
}
.blog-details{
	.quote{
		margin-bottom: 20px;
		.quote-container{
			margin-bottom: 38px;
			width: 100%;
			position: relative;
			padding:30px 28px;
			color: #6d6d6d;
			font-size: 18px;
			border:1px solid #dfdfdf;
			.quote-copy{
				background-image:url('/images/icon-quote-left.svg'), url('/images/icon-quote-right.svg');
				background-repeat: no-repeat, no-repeat;
				background-position: top left, bottom right;
			}
			&:after{
				bottom: -25px;
				left: -1px;
				position: absolute;
				content:"";
				width: 23px;
				height: 25px;
				background:transparent url('/images/quote-bubble-leg.png') left top no-repeat;
				@include breakpoint(tablet) {
					-moz-transform: scaleY(-1);
					-webkit-transform: scaleY(-1);
					-o-transform: scaleY(-1);
					transform: scaleY(-1);
					-ms-filter: flipv; /*IE*/
					filter: flipv;
					bottom:auto;
					top:-25px;
				}
			}
		}
		.cite-container{
			.avatar{
				margin-right: 15px;
				width: 51px;
				height: 51px;
				@include border-radius(90%);
				overflow:hidden;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
			.name{
				text-align: left;
				color: $medium-grey;
				font-size: 23px;
			}
			.honours-title{
				text-align: left;
				font-size: 13px;
				color: #bcbcbc;
				font-style: italic;
			}
		}
		.cite-container-desktop{
			@include breakpoint(tablet) {
				display: none;
			}
		}
		.cite-container-mobile{
			display: none;
			.avatar{
				width: 110px;
				height: 110px;
				margin: 0 auto;
				margin-bottom: 20px;
			}
			.name{
				text-align: center;
			}
			.honours-title{
				text-align: center;
				margin-bottom: 15px;
			}
			@include breakpoint(tablet) {
				display: block;
			}
		}
	}
	.download, .link{
		position: relative;
		margin-bottom: 20px;
		.download-container{
			position: relative;
			min-height: 110px;
			padding:30px 28px 30px 125px;
			color: #6d6d6d;
			font-size: 18px;
			border:1px solid #dfdfdf;
			&:before{
				position: absolute;
				top: 25px;
				left: 35px;
				width: 58px;
				height: 58px;
				content:"";
				background-size: 58px;
			}
			@include breakpoint(tablet) {
				min-height: 1px;
				padding:30px 28px 30px 28px;
			}
		}
		.post-data{
			background:none;
			margin-top: 0;
		}
	}
	.download{
		.download-container{
			&:before{
				background:transparent url('/images/icon-download.png') left center no-repeat;
				background-size: 58px;
				@include breakpoint(tablet) {
					background: transparent;
				}
			}
		}
		
	}
	.link{
		.download-container{
			&:before{
				background:transparent url('/images/icon-link.png') left center no-repeat;
				background-size: 58px;
				@include breakpoint(tablet) {
					background: transparent;
				}
			}
		}
		
	}
}

.related-post-img-container {
	background-repeat: no-repeat;
	background-position: center;
	packground-size: cover;
}

.pagination-disabled{
	display: none;
	@include breakpoint(tablet) {
		display: block;
		pointer-events: none;
		background-color: #C1C1C1 !important;
	}
}