.tablet-hidden {
    @include breakpoint(tablet) {
        display: none;
    }
}

.tablet-no-pad {
    @include breakpoint(tablet) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

section.partial {
    padding: 50px 0;
}

hr.page-break {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #dcdcdc;
    margin: 60px 0;
    padding: 0;
    @include breakpoint(tablet) {
        display: none;
    }
}

.quick-quote {
    padding: 12px;
    margin-top: -43px;
    min-height: 70px;
    border: 6px solid white;
    background-color: lighten($color-secondary, 30%);
    @include border-radius(8px);
    @include breakpoint(tablet-large) {
        display: none;
        margin-top: 0;
    }

    .btn-primary {
        img {
            margin-top: -2px;
            margin-right: 14px;
        }
    }
    .quote-copy {
        margin-top: 8px;
        display: inline-block;
        padding-left: 20px;
        color: $medium-grey;
        font-size: 20px;
    }
}

.mobile-cta {
    padding: 20px 0 20px 0;
    background-color: lighten($color-secondary, 30%);
    min-height: 130px;
    text-align: center;
    color: $medium-grey;
    font-size: 20px;
    display: none;
    @include breakpoint(tablet-large) {
        display: block;
    }

    @include breakpoint(tablet) {
        min-height: 130px;
    }

    .btn-primary {
        width: 240px;
        margin: 0 auto;
        margin-top: 10px;
        background-color: $color-secondary;
        border-color: $color-secondary;
        &:hover,
        &:focus,
        &:active {
            background-color: darken($color-secondary, 10%);
            border-color: darken($color-secondary, 10%);
        }
    }
}

.mobile-cta-yd {
    background-color: $color-secondary;
    padding: 30px 0;
    .btn-primary img {
        margin-top: -2px;
        margin-right: 14px;
    }
}

.young-drivers-signup {
    margin-top: 0px;
    background-color: $color-secondary;
    border: none;
    .quote-copy {
        color: #fff;
    }
    .btn-primary {
        background-color: transparent;
        border: 1px solid #fff;
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: 1px solid #fff;
        }
    }
}

.well {
    padding: 50px 0;
    margin-bottom: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    @include border-radius(0px);
    h1 {
        margin-top: 0;
        margin-bottom: 10px;
    }
    @include breakpoint(tablet) {
        padding: 30px 0;
    }
}

.well-primary {
    margin-top: 0;
    padding-bottom: 50px;
    background-color: $color-secondary;
    .copy {
        padding-top: 57px;
        color: #fff;
        text-align: center;
        font-size: 22px;
        @include breakpoint(tablet) {
            font-size: 14px;
            padding-top: 31px;
        }
    }
    @include breakpoint(tablet) {
        padding-bottom: 30px;
    }
}

.well-secondary {
    padding: 75px 0;
    background-color: $off-white;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    @include breakpoint(tablet-large) {
        padding: 40px 0;
    }

    .products-panel-group {
        @include breakpoint(tablet) {
            margin-bottom: 5px;
        }
    }
}

.well-elevator-pitch {
    padding-top: 0;
    background-color: $color-accent;
    .btn-primary {
        background-color: $color-secondary;
        border-color: $color-secondary;
        &:hover,
        &:focus,
        &:active {
            background-color: darken($color-secondary, 10%);
            border-color: darken($color-secondary, 10%);
        }
    }
    .copy {
        @include breakpoint(tablet-large) {
            font-size: 18px;
        }
    }
}

.well-default {
    background: #ffffff;
    padding-bottom: 10px;
    .heading {
        font-family: $font-secondary;
        color: $medium-grey;
        font-size: 50px;
        @include breakpoint(tablet) {
            font-size: 22px;
        }
    }
    .copy {
        padding: 0;
        text-align: left;
        color: $medium-grey;
    }
    .wysiwyg {
        margin-top: 40px;
    }
}

.well-young {
    background: $color-primary url("/images/bg-youngdrivers.png") center bottom no-repeat;
    @include breakpoint(tablet) {
        background: $color-primary center bottom no-repeat;
    }

    .heading {
        font-family: $font-secondary;
        color: #fff;
        font-size: 50px;
        @include breakpoint(tablet) {
            font-size: 28px;
        }
    }
    .copy {
        padding: 0;
        text-align: left;
    }
}

.mobile-young-drivers-image {
    display: none;
    background: url("/images/mobile-young-drivers.png") center bottom no-repeat;
    min-height: 285px;
    background-size: cover;
    @include breakpoint(tablet) {
        display: block;
    }
}

.well-content {
    background: $off-white;
    .heading {
        font-family: $font-secondary;
        color: $medium-grey;
        font-size: 50px;
        @include breakpoint(tablet) {
            font-size: 28px;
        }
    }
    .copy {
        padding: 0;
        text-align: left;
        color: $medium-grey;
    }
}

.chapter-heading {
    font-size: 30px;
    color: $medium-grey;
    @include breakpoint(tablet-large) {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.chapter-subheading {
    padding-bottom: 35px;
    font-size: 15px;
    color: #6e6e6e;
    text-align: center;
    @include breakpoint(tablet-large) {
        display: none;
    }
}

.thumbnail-products {
    @include border-radius(0);
    padding: 0;
    min-height: 520px;
    @include breakpoint(tablet-large) {
        min-height: 420px;
    }

    .read-more-icon {
        @include breakpoint(tablet-large) {
            right: 19px;
        }
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-color: #ddd !important;
        .read-more-icon {
            transition: width 0.1s;
            text-decoration: none;
            width: 210px;
            // width: 75%;
            line-height: 32px;
            @include border-radius(25px);
            @include breakpoint(desktop-large) {
                width: 180px;
            }

            @include breakpoint(tablet-large) {
                width: 180px;
            }

            .closed {
                display: none;
            }
            .opened {
                display: inline;
            }
            .opened-chevron {
                display: block;
            }
        }
    }
    .image-container {
        @include breakpoint(tablet) {
            display: block;
            max-height: 175px;
            overflow: hidden;
        }
    }
    .thumb-image {
        width: 100%;
        &.home-mobile {
            @include breakpoint(tablet) {
                margin-top: -100px;
                vertical-align: middle;
            }

            @include breakpoint(phone) {
                margin-top: 0;
                vertical-align: middle;
            }
        }
        &.home-desktop {
            @include breakpoint(tablet) {
                display: none;
            }
        }
        &.home-mobile {
            display: none;
            @include breakpoint(tablet) {
                display: inline;
            }
        }
    }
    .caption {
        position: relative;
        text-align: center;
        padding: 40px 30px 30px 30px;
        min-height: 220px;
        @include breakpoint(tablet-large) {
            padding: 40px 15px 30px 15px;
        }

        .sub-heading {
            display: block;
            color: #d4d4d4;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
        }
        .heading {
            display: block;
            font-size: 27px;
            @include breakpoint(tablet-large) {
                font-size: 24px;
            }
        }
        .copy {
            display: block;
            font-size: 15px;
            color: #6d6d6d;
        }
        .icon-container {
            padding-top: 15px;
            height: 66px;
        }
    }
}

.thumbnail-young {
    min-height: 548px;
    @include breakpoint(tablet) {
        min-height: 515px;
    }

    .icon-container {
        padding-top: 55px;
        display: block;
        background-color: $color-primary;
        width: 215px;
        height: 215px;
        @include border-radius(50%);
        overflow: hidden;
        img {
            display: block;
            margin: 0 auto;
        }
        @include breakpoint(tablet-large) {
            padding-top: 40px;
            width: 150px;
            height: 150px;
        }

        @include breakpoint(tablet) {
            padding-top: 55px;
            width: 215px;
            height: 215px;
        }
    }
    .img-container {
        margin: 0 auto;
        margin-top: 40px;
        position: relative;
        text-align: center;
        width: 215px;
        height: 215px;
        @include breakpoint(tablet-large) {
            width: 150px;
            height: 150px;
            img {
                height: 70px;
            }
        }

        @include breakpoint(tablet) {
            width: 215px;
            height: 215px;
            img {
                height: auto;
            }
        }
    }
    .step-badge {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 42px;
        height: 42px;
        @include border-radius(50%);
        border: 3px solid $white;
        background-color: $color-primary;
        color: $white;
        font-size: 18px;
        line-height: 39px;
        text-align: center;
        @include breakpoint(tablet-large) {
            right: 0px;
            bottom: 0px;
        }

        @include breakpoint(tablet) {
            right: 10px;
            bottom: 10px;
        }
    }
    .heading {
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 27px;
        color: $color-primary;
        background: transparent url("/images/breaker-sidebar.png") center bottom no-repeat !important;
        @include breakpoint(tablet) {
            font-size: 27px;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }
}

.cw50 {
    float: left;
    width: 50%;
    min-height: 350px;
    @include breakpoint(tablet) {
        width: 100%;
    }

    &.image {
        position: relative;
        background-size: cover;
        @include breakpoint(tablet-large) {
            background-position: right;
        }

        @include breakpoint(tablet) {
            background-size: 130% auto;
            min-height: 240px;
        }

        .read-more-icon {
            background-color: $color-secondary;
            @include breakpoint(tablet) {
                width: 210px;
                line-height: 32px;
                top: auto !important;
                left: 0 !important;
                right: 0 !important;
                bottom: -21px;
                margin: 0 auto;
                .closed {
                    display: none;
                }
                .opened {
                    display: inline;
                }
                .opened-chevron {
                    display: block;
                }
            }
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            border-color: #ddd !important;
            .read-more-icon {
                transition: width 0.1s;
                text-decoration: none;
                width: 210px;
                // width: 75%;
                line-height: 32px;
                @include border-radius(25px);
                .closed {
                    display: none;
                }
                .opened {
                    display: inline;
                }
                .opened-chevron {
                    display: block;
                }
            }
        }
    }
    &.left {
        .read-more-icon {
            top: 45%;
            right: -20px;
        }
    }
    &.right {
        .read-more-icon {
            top: 45%;
            left: -20px;
        }
    }
    &.dark.copy {
        background-color: $color-primary;
        .heading {
            color: $white;
        }
        .sub-heading {
            color: $white;
        }
        ul {
            color: $white;
        }
    }
    &.light.copy {
        background-color: $color-secondary;
        .heading {
            color: $white;
        }
        .sub-heading {
            color: $white;
        }
        ul {
            color: $white;
        }
    }
    &.copy {
        padding: 50px;
        background-color: #fff;
        @include breakpoint(tablet-large) {
            padding: 30px;
        }

        @include breakpoint(tablet) {
            padding: 60px 30px 30px 30px;
        }

        .heading {
            color: #2f2f2f;
            font-size: 30px;
        }
        .sub-heading {
            color: #2f2f2f;
            font-size: 15px;
        }
        ul {
            display: initial;
            position: relative;
            padding-left: 0px;
            margin-bottom: 20px;
            font-size: 15px;
            color: #6d6d6d;
            font-family: $font-primary;
            list-style: none;
            li {
                padding-left: 13px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            li:before {
                position: absolute;
                font-size: 16px;
                left: 0px;
                content: "\2022";
                color: $color-secondary;
            }
        }
    }
}

.tablet-cw50-image {
    display: none;
    @include breakpoint(tablet) {
        display: block;
    }
}

.desktop-cw50-image {
    @include breakpoint(tablet) {
        display: none;
    }
}

.next-page-link {
    margin-top: 12px;
    font-size: 13px;
    color: $color-primary;
    text-transform: uppercase;
    font-weight: 800;
    .icon {
        margin-left: 11px;
        font-size: 12px;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: darken($color-primary, 10%);
    }
}

.thumbnail-blog {
    margin-top: 15px;
    @include border-radius(0);
    padding: 0;
    min-height: 463px;
    @include breakpoint(tablet-large) {
        min-height: 481px;
    }

    .thumb-image {
        width: 100%;
        min-height: 157px;
    }
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-color: $color-primary !important;
    }
    .btn-primary {
        bottom: 31px;
    }
    .caption {
        padding: 18px 26px;
        .title {
            font-size: 18px;
            color: $medium-grey;
        }
        .timestamp {
            margin-top: 18px;
            margin-bottom: 10px;
            padding-top: 18px;
            font-size: 13px;
            color: #bcbcbc;
            text-transform: uppercase;
            background: transparent url("/images/timestamp-border.png") left top no-repeat;
            img {
                margin-top: -2px;
            }
        }
        .copy {
            color: #6d6d6d;
            font-size: 15px;
            margin-bottom: 10px;
        }
        .quote-container {
            margin-bottom: 38px;
            position: relative;
            padding: 30px 28px;
            color: #6d6d6d;
            font-size: 15px;
            border: 1px solid #dfdfdf;
            background-image: url("/images/icon-quote-left.svg"), url("/images/icon-quote-right.svg");
            background-repeat: no-repeat, no-repeat;
            background-position: 10% 10%, 90% 90%;
            &:after {
                bottom: -25px;
                left: -1px;
                position: absolute;
                content: "";
                width: 23px;
                height: 25px;
                background: transparent url("/images/quote-bubble-leg.png") left top no-repeat;
            }
        }
        .cite-container {
            .avatar {
                margin-right: 15px;
                width: 51px;
                height: 51px;
                @include border-radius(45px);
                overflow: hidden;
            }
            .name {
                color: $medium-grey;
                font-size: 23px;
            }
            .honours-title {
                font-size: 13px;
                color: #bcbcbc;
                font-style: italic;
            }
        }
    }
}

.error-page {
    text-align: center;
    .heading {
        margin: 0 auto;
        margin-top: 100px;
        width: 228px;
        color: $color-accent;
        font-size: 122px;
        font-family: $font-primary;
        font-weight: 800;
        border-bottom: 6px solid $color-accent;
    }
    .subheading {
        margin-top: 20px;
        font-size: 13px;
    }
    .simp {
        margin-bottom: 100px;
    }
}

.pagination {
    @include breakpoint(tablet) {
        width: 100%;
        margin-bottom: 0;
        margin-top: 0;
    }

    li {
        a, span {
            padding: 9px 15px 8px 15px;
            margin-left: 5px;
            margin-right: 5px;
            color: $color-secondary;
            font-size: 15px;
            font-weight: 800;
            text-transform: uppercase;
            border-color: $color-secondary;
            &:hover,
            &:active,
            &:focus {
                border-color: $color-secondary;
                background-color: $color-secondary;
                color: $white;
            }
            @include border-radius(2px);
            @include breakpoint(tablet) {
                display: none;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &:first-child a, &:first-child span {
            position: relative;
            text-align: center;
            padding: 0px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            width: 41px;
            height: 40px;
            color: $white;
            &:before {
                content: "\f053";
                position: absolute;
                font: normal normal normal 14px/1 FontAwesome;
                top: 13px;
                left: 13px;
                color: $color-secondary;
                @include breakpoint(tablet) {
                    top: 19px;
                    color: $white;
                }
            }
            &:hover,
            &:focus,
            &:active {
                color: transparent;
                &:before {
                    color: $white;
                }
            }
            @include breakpoint(tablet) {
                display: block;
                width: 50%;
                height: 55px;
                background-color: $color-secondary;
                color: $color-secondary;
                @include border-radius(0);
                border-color: transparent;
                &:after {
                    content: "Previous";
                    color: #fff;
                    font-size: 20px;
                    text-transform: capitalize;
                    line-height: 52px;
                }
            }
        }
        &:last-child a, &:last-child span {
            position: relative;
            text-align: center;
            padding: 0px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            width: 41px;
            height: 40px;
            color: $white;
            &:before {
                content: "\f054";
                position: absolute;
                font: normal normal normal 14px/1 FontAwesome;
                top: 13px;
                right: 13px;
                color: $color-secondary;
                @include breakpoint(tablet) {
                    top: 19px;
                    color: $white;
                }
            }
            &:hover,
            &:focus,
            &:active {
                color: transparent;
                &:before {
                    color: $white;
                }
            }
            @include breakpoint(tablet) {
                display: block;
                width: 50%;
                height: 55px;
                background-color: $color-primary;
                color: $color-primary;
                @include border-radius(0);
                border-color: transparent;
                &:after {
                    content: "Next";
                    color: #fff;
                    font-size: 20px;
                    text-transform: capitalize;
                    line-height: 52px;
                }
            }
        }
        &.disabled {
            a, span {
                @include breakpoint(tablet) {
                    color: #C1C1C1;
                }

                &:before {
                    color: #ddd;
                    @include breakpoint(tablet) {
                        color: #fff;
                    }
                }
                &:hover,
                &:active,
                &:focus {
                    color: transparent;
                    border-color: transparent;
                    &:before {
                        color: #ddd;
                        @include breakpoint(tablet) {
                            color: #fff;
                        }
                    }
                    @include breakpoint(tablet) {
                        background-color: #C1C1C1;
                        &:after {
                            color: $white;
                        }
                    }
                }
                @include breakpoint(tablet) {
                    background-color: #C1C1C1;
                    @include border-radius(0);
                    border-color: transparent;
                }
            }
        }
        &.active {
            span {
                background-color: $color-secondary;
                border-color: $color-secondary;
                color: #fff;
                &:hover,
                &:focus,
                &:active {
                    background-color: darken($color-secondary, 10%);
                    border-color: darken($color-secondary, 10%);
                }
            }
        }
    }
}

section.content-template {
    .header {
        text-align: left;
        h1 {
            font-weight: 800;
            margin-bottom: 20px;
        }
    }
    .sub-heading {
        font-size: 22px;
        color: $medium-grey;
    }
    .post-data {
        text-align: center;
    }
}

.thumb-gallery {
    margin-bottom: 40px;
    @include breakpoint(tablet) {
        margin-bottom: 0;
    }

    .cw50 {
        min-height: 485px;
        @include breakpoint(tablet) {
            min-height: 300px;
        }
    }
    .copy {
        @include breakpoint(tablet) {
            padding: 25px 15px 25px 15px;
        }
    }
    .heading {
        margin-bottom: 30px;
        padding-bottom: 25px;
        font-size: 30px;
        font-family: $font-secondary;
        text-transform: uppercase;
        background-image: url("/images/breaker-products.png");
        background-position: left bottom;
        background-repeat: no-repeat;
    }
    .copy .heading {
        @include breakpoint(tablet) {
            font-size: 22px;
            margin-bottom: 18px;
            padding-bottom: 17px;
        }
    }
    .sub-heading {
        font-size: 15px;
        font-family: $font-tert;
    }
    .btn-secondary {
        margin-top: 30px;
    }
}

.page-heading {
    padding: 35px 0;
    text-align: center;
    .heading {
        font-size: 30px;
        color: $medium-grey;
    }
    .copy {
        font-size: 18px;
        color: #6e6e6e;
    }
}

.js-social-follower {
    width: 262px;
    .social-icons {
        margin-top: 0 !important;
    }
}

.js-stick {
    transition: all 0.1s;
    position: fixed;
    top: 102px;
    width: 262px !important;
}

.link-caption .timestamp {
    margin-top: 0 !important;
}

.download-home, .link-home {
    position: relative;
    .download-container {
        position: relative;
        text-align: center;
        margin: 20px 20px 0 20px;
        min-height: 110px;
        padding: 105px 20px 30px 20px;
        color: #6d6d6d;
        font-size: 18px;
        border: 1px solid #dfdfdf;
        &:before {
            position: absolute;
            top: 25px;
            left: 0px;
            right: 0;
            margin: 0 auto;
            width: 58px;
            height: 58px;
            content: "";
            background-size: 58px;
        }
    }
    a {
        &:hover,
        &:active,
        &:focus {
            .download-container {
                background-color: $off-white;
            }
            text-decoration: none;
        }
    }
    .post-data {
        background: none;
        margin-top: 0;
    }
}

.download-home {
    .download-container {
        &:before {
            background: transparent url("/images/icon-download.png") center top no-repeat;
            background-size: 58px;
        }
    }
}

.link-home {
    .download-container {
        &:before {
            background: transparent url("/images/icon-link.png") center top no-repeat;
            background-size: 58px;
        }
    }
}

.alert {
    margin-top: 20px;
}

.home-alert-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
    .alert {
        margin-top: 0;
    }
}

.products-mobile {
    display: none;
    padding-bottom: 20px;
    @include breakpoint(tablet) {
        display: block;
    }
}

.products-desktop {
    @include breakpoint(tablet) {
        display: none;
    }
}

.products-panel-group {
    margin-bottom: 50px;
    .panel-default {
        margin-bottom: -6px !important;
        &:first-of-type {
            .panel-heading {
                -webkit-border-top-left-radius: 5px;
                -webkit-border-top-right-radius: 5px;
                -moz-border-radius-topleft: 5px;
                -moz-border-radius-topright: 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }
        &:last-of-type {
            .panel-heading {
                -webkit-border-bottom-right-radius: 5px;
                -webkit-border-bottom-left-radius: 5px;
                -moz-border-radius-bottomright: 5px;
                -moz-border-radius-bottomleft: 5px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
    }
    .panel-heading {
        padding: 0;
        background-color: #fff;
        @include border-radius(0px);
    }
    .panel-title {
        position: relative;
        font-size: 21px;
        color: $medium-grey;
        a {
            padding: 30px 18px 30px 59px;
            text-align: center;
            display: block;
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
        .icon {
            left: 15px;
            top: 27px;
            position: absolute;
        }
        .chevron {
            transition: all 0.1s;
            margin-top: 8px;
            float: right;
        }
    }
    .panel-body {
        background-color: $off-white;
        color: #4d4d4d;
        font-size: 18px;
        text-align: center;
    }
    .open {
        .chevron {
            transition: all 0.1s;
            -ms-transform: rotate(180deg);
            /* IE 9 */
            -webkit-transform: rotate(180deg);
            /* Chrome, Safari, Opera */
            transform: rotate(180 deg);
        }
    }
    .btn-primary {
        margin-top: 20px;
        width: 100%;
    }
    .step-badge {
        position: absolute;
        top: 19px;
        left: 15px;
        line-height: 46px;
        width: 45px;
        height: 45px;
        background-color: $color-primary;
        color: white;
        @include border-radius(50%);
    }
}

.gal-container {
    @include breakpoint(tablet) {
        padding-bottom: 0;
    }
}

.young-drivers-page-heading {
    @include breakpoint(tablet) {
        display: none;
    }
}

.young-well-secondary {
    @include breakpoint(tablet) {
        padding-bottom: 0px;
    }
}

.paginaton-container {
    @include breakpoint(tablet) {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -27px;
    }
}

.contains-link {
    &:hover,
    &:focus,
    &:active {
        border-color: $color-primary !important;
    }
}